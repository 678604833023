import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Project } from '@penji/shared/data-access';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ModalGeneralAssignComponent } from '@penji/team/shared/shared-ui/modal-general-assign';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { take } from 'rxjs';

@Component({
  selector: 'penji-project-reassign',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent
  ],
  templateUrl: './project-reassign.component.html',
  styleUrls: ['./project-reassign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectReassignComponent {

  ProjectStoreService = inject(ProjectStoreService);
  modal = inject(NzModalService);
  @Input() project_data!: Project;

  async openReassign() {
    const ref: NzModalRef = this.modal.create({
      nzContent: ModalGeneralAssignComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        list_user_id: this.project_data.assign_by,
        role: [2,4,7]
      },
      nzTitle: 'Reassign',
      nzWidth: '405px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
    const list_out_assign_by: string[] = await ref.afterClose.pipe(take(1)).toPromise();
    if (list_out_assign_by) {
      let data = new Project();
      data = {...this.project_data};
      data.assign_by = list_out_assign_by;
      this.ProjectStoreService.updateProject$(data);
    }
  }
}
