import { Timestamp } from '@firebase/firestore-types';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

export class Payout {
    id!: string;
    doc!: QueryDocumentSnapshot<DocumentData>;
    designer_id!: string;
    team_designer_id!: string;
    approved!: boolean;
    title!: string;
    base!: number;
    bonus!: number;
    healthcare!: number;
    work!: number;
    absences!: number;//include abs pto & not pto
    pto!: number;
    deductions!: number;
    start_date!: Timestamp;
    end_date!: Timestamp;
    created_at!: Timestamp;
    over_time!: number;
    over_time_rate?: number;
    currency_peso?: number;
    total_usd!: number;
    note?: string;
    log_type = 'payout';
}
