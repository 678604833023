<nz-footer class="px-4 lg:px-0">
  <div class="py-5 xl:py-10 bg-[#ffffff] w-full">
    <div class="container">
      <ngx-slick-carousel
        class="carousel custom-slick-carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div
          ngxSlickItem
          class="slide mx-2"
          *ngFor="let company of footerData.companies"
        >
          <img src="{{ company.logo }}" alt="{{ company.name }}" />
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div
    class="px-8 md:px-3 pt-10 xl:pt-20 w-full border border-b-0 border-l-0 border-r-0 pb-72 md:pb-56 relative min-[480px]:!bg-[length:auto_40%] min-[480px]:!bg-[position:bottom_left] min-[1024px]:!bg-[length:100%_auto] min-[1024px]:!bg-[position:center_bottom]"
    style="background: url('{{footerData.bgUrl}}') no-repeat 0 bottom / auto 300px"
  >
    <div class="container bg-white">
      <div class="flex flex-col min-[480px]:flex-col-reverse md:flex-row">
        <div class="md:w-[30%] lg:w-[20%] flex items-center justify-between min-[480px]:flex-col min-[480px]:my-14 md:flex-col md:justify-start md:items-start">
            <a href="/" class="block-logo flex items-center justify-center min-[480px]:mb-3 md:mb-0 md:justify-start">
                <img
                src="assets/images/layout-images/Logo-purple.svg"
                alt="Penji logo purple"
                class="max-[479px]:mr-3 min-[480px]:mr-0"
                />
            </a>
            <div class="hidden md:block flex items-center justify-center min-[480px]:mb-3 md:mb-0 md:justify-start">
                <img
                    src="assets/images/layout-images/designed-with-heart.svg"
                    alt="Penji 2022"
                    class="mr-3 mt-2 md:mt-0"
                />
            </div>
            <div class="hidden min-[480px]:block min-[480px]:order-2 md:order-1 md:block flex items-center justify-center md:justify-start text-[#AEB5BD] text-base md:text-[14px] mt-2.5">
                ©Penji 2022
            </div>
            <div
                class="social-icon-group flex items-center justify-center md:justify-start md:mt-3 md:order-2"
            >
                <ng-container *ngFor="let social of footerData.socials">
                    <a
                    href="{{ social.url }}"
                    class="icon-wrap flex items-center justify-center hover:opacity-75 ml-3 md:ml-0 md:mr-3"
                    >
                    <!-- fb -->
                    <svg *ngIf="social.platform === 'facebook' "width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle class="transition-stroke ease duration-500" cx="21" cy="21.7601" r="20.25" fill="white" stroke="#E9ECEF" stroke-width="1.5"/>
                    <path class="transition-fill ease duration-500" d="M26.1154 23.2594L26.8157 18.9161H22.4383V16.0976C22.4383 14.9096 23.0496 13.7509 25.0102 13.7509H27V10.0534C27 10.0534 25.1945 9.76013 23.4678 9.76013C19.8632 9.76013 17.5071 11.8406 17.5071 15.6064V18.9169H13.5V23.2601H17.5071V33.7601H22.4383V23.2601L26.1154 23.2594Z" fill="#AEB5BD"/>
                    </svg>

                    <!-- ig -->
                    <svg *ngIf="social.platform === 'instagram'" width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle class="transition-stroke ease duration-500" cx="21" cy="21.7601" r="20.25" fill="white" stroke="#E9ECEF" stroke-width="1.5"/>
                    <path class="transition-fill ease duration-500" d="M20.9972 18.2586C19.0692 18.2586 17.4958 19.8321 17.4958 21.7601C17.4958 23.6882 19.0692 25.2616 20.9972 25.2616C22.9252 25.2616 24.4986 23.6882 24.4986 21.7601C24.4986 19.8321 22.9252 18.2586 20.9972 18.2586ZM31.4988 21.7601C31.4988 20.3102 31.512 18.8733 31.4305 17.426C31.3491 15.7448 30.9656 14.2528 29.7363 13.0235C28.5044 11.7915 27.015 11.4106 25.3339 11.3292C23.8839 11.2478 22.4471 11.2609 20.9998 11.2609C19.5499 11.2609 18.113 11.2478 16.6657 11.3292C14.9846 11.4106 13.4926 11.7941 12.2633 13.0235C11.0314 14.2554 10.6505 15.7448 10.5691 17.426C10.4876 18.8759 10.5008 20.3128 10.5008 21.7601C10.5008 23.2075 10.4876 24.647 10.5691 26.0943C10.6505 27.7754 11.034 29.2675 12.2633 30.4968C13.4952 31.7287 14.9846 32.1096 16.6657 32.1911C18.1157 32.2725 19.5525 32.2594 20.9998 32.2594C22.4498 32.2594 23.8866 32.2725 25.3339 32.1911C27.015 32.1096 28.507 31.7261 29.7363 30.4968C30.9682 29.2648 31.3491 27.7754 31.4305 26.0943C31.5146 24.647 31.4988 23.2101 31.4988 21.7601ZM20.9972 27.1476C18.0158 27.1476 15.6098 24.7415 15.6098 21.7601C15.6098 18.7787 18.0158 16.3726 20.9972 16.3726C23.9785 16.3726 26.3846 18.7787 26.3846 21.7601C26.3846 24.7415 23.9785 27.1476 20.9972 27.1476ZM26.6052 17.4102C25.9092 17.4102 25.347 16.8481 25.347 16.152C25.347 15.4559 25.9092 14.8937 26.6052 14.8937C27.3013 14.8937 27.8634 15.4559 27.8634 16.152C27.8636 16.3173 27.8312 16.481 27.7681 16.6337C27.7049 16.7865 27.6123 16.9253 27.4954 17.0421C27.3785 17.159 27.2397 17.2517 27.087 17.3148C26.9342 17.378 26.7705 17.4104 26.6052 17.4102Z" fill="#AEB5BD"/>
                    </svg>
                    
                    <!-- in -->
                    <svg *ngIf="social.platform === 'linkedin'" width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle class="transition-stroke ease duration-500" cx="21" cy="21.7601" r="20.25" fill="white" stroke="#E9ECEF" stroke-width="1.5"/>
                    <path class="transition-fill ease duration-500" d="M14.9267 30.7601V17.6035H10.7466V30.7601H14.9267ZM12.8372 15.8061C14.2948 15.8061 15.2021 14.7958 15.2021 13.5333C15.175 12.2424 14.2949 11.2601 12.8648 11.2601C11.435 11.2601 10.5 12.2424 10.5 13.5333C10.5 14.7959 11.4071 15.8061 12.8099 15.8061H12.8372ZM17.2403 30.7601H21.4203V23.4128C21.4203 23.0196 21.4475 22.6268 21.5579 22.3457C21.86 21.5601 22.5478 20.7464 23.7025 20.7464C25.2151 20.7464 25.8202 21.9529 25.8202 23.7215V30.76H30V23.2162C30 19.175 27.9378 17.2946 25.1876 17.2946C22.9326 17.2946 21.9425 18.6133 21.3925 19.5114H21.4204V17.6032H17.2404C17.2952 18.8378 17.2403 30.7601 17.2403 30.7601Z" fill="#AEB5BD"/>
                    </svg>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="md:w-[70%] lg:w-[80%]">
          <div nz-row class="mt-6 md:mt-0 text-center md:text-left">
            <div class="hidden min-[480px]:block mt-2 md:mt-0" nz-col [nzSpan]="6" nzXs="12" nzMd="12" nzLg="8" nzXl="6" *ngFor="let item of footerData.items">
              <h3 class="text-lg font-lab-semibold text-lg mb-4 lg:mb-8">
                {{ item.title }}
              </h3>
              <p
                class="text-base text-normal text-gray-400 mb-2.5 lg:mb-5"
                *ngFor="let item of item.items"
              >
                <a class="hover:!text-purple" href="{{ item.url }}">{{ item.title }}</a>
              </p>
            </div>
            
            <div class="bottom-footer-links min-[480px]:hidden w-full">
                <ng-container *ngFor="let item of footerData.items; let i = index">
                    <nz-collapse nzExpandIconPosition="right">
                        <nz-collapse-panel
                            [nzHeader]="title"
                            [nzActive]="item.active"
                            class="w-full bg-white"
                            nzBordered="false"
                            nzShowArrow="false"
                        >
                            <div class="mt-2 md:mt-0 bg-white w-full">
                                <p
                                    class="text-base text-normal text-left text-gray-400 mb-2.5 lg:mb-5 py-2"
                                    *ngFor="let item of item.items"
                                >
                                    <a class="hover:!text-purple" href="{{ item.url }}">{{ item.title }}</a>
                                </p>
                            </div>
                            <ng-template #title>
                                <h3 class="relative font-lab-semibold text-[18px] text-purple w-full px-2 py-3 text-left">
                                    {{ item.title }}
                                    <span class="arrow-icon absolute block w-[.75rem] h-[.75rem] right-[10px] top-[19px] before:content-[''] before:block before:w-[.75rem] before:h-[.75rem] before:bg-[#aeb5bd] after:content-[''] after:block after:w-[.75rem] after:h-[.75rem] after:bg-white before:absolute after:absolute before:right-0 after:right-[-2.4px] before:top-[0] after:top-[-2.5px] -rotate-[135deg] transition"></span>
                                </h3>
                            </ng-template>
                        </nz-collapse-panel>
                    </nz-collapse>
                    <nz-divider *ngIf="i < footerData.items.length - 1" class="my-3"></nz-divider>
                </ng-container>
            </div>
          </div>
        </div>
        <div class="min-[480px]:hidden flex items-center justify-end text-[#AEB5BD] text-base mt-2.5 absolute bottom-10 right-6">
            ©Penji 2022
        </div>
    </div>
    </div>
  </div>
</nz-footer>
