<div class="flex items-center" nz-dropdown [nzDropdownMenu]="menu">
  <penji-input container_class_name="md:w-96" input_class_name="h-10 pr-8" [control]="search_control" icon="search"
    icon_size="20px" placeholder="Search" [auto_focus]="true" [enable_clear_button]="true">
  </penji-input>

  <div *ngIf="isLoading" class="rounded-full bg-white inline-flex p-1 items-center ml-2">
    <penji-spinner size="25px"></penji-spinner>
  </div>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <ng-container *ngIf="list$ | async as vm">
      <ng-container *ngIf="vm.list_user && vm.list_user.length > 0">
        <li nz-menu-item class="cursor-default text-uppercase font-bold color-purple">
          Users:
        </li>
        <ng-container *ngFor="let user of vm.list_user">
          <li nz-menu-item *ngIf="!select">
            <ng-container [ngSwitch]="user.role">
              <ng-container *ngSwitchCase="'3'">
                <a class="px-4 py-2 d-block"
                  (click)="onAddKeyword('customer',user?.user_info?.first_name + ' ' +user?.user_info?.last_name, user?.objectID)">
                  <p class="m-0 text-capitalize">
                    {{user?.user_info?.first_name}} {{user?.user_info?.last_name}}
                    <span class="color-pink">- <penji-user-role [role]="user?.role"></penji-user-role></span>
                  </p>
                </a>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <a class="px-4 py-2 d-block"
                  (click)="onAddKeyword('member',user?.user_info?.first_name + ' ' +user?.user_info?.last_name, user?.objectID)">
                  <p class="m-0 text-capitalize">
                    {{user?.user_info?.first_name}} {{user?.user_info?.last_name}}
                    <span class="color-pink">- <penji-user-role [role]="user?.role"></penji-user-role></span>
                  </p>
                </a>
              </ng-container>
            </ng-container>
          </li>
          <li nz-menu-item *ngIf="select">
            <a class="px-4 py-2 d-block" (click)="selectUser(user?.objectID)">
              <p class="m-0 text-capitalize">
                {{user?.user_info?.first_name}} {{user?.user_info?.last_name}}
                <span class="color-pink">- <penji-user-role [role]="user?.role"></penji-user-role></span>
              </p>
            </a>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="vm.list_project && vm.list_project.length > 0">
        <li nz-menu-item class="cursor-default text-uppercase font-bold color-purple">Projects:</li>
        <li nz-menu-item *ngFor="let project of vm.list_project">
          <a class="px-4 py-2 d-block" (click)="onAddKeyword('project', project.title, '', project?.id)">
            <p class="m-0 text-capitalize" [innerHTML]="project?.title"></p>
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="vm.list_ticket && vm.list_ticket.length > 0">
        <li nz-menu-item class="cursor-default text-uppercase font-bold color-purple">Tickets:</li>
        <li nz-menu-item *ngFor="let ticket of vm.list_ticket">
          <a class="px-4 py-2 d-block" (click)="onAddKeyword('ticket', ticket.index_number, '', '', ticket?.id)">
            <p class="m-0 text-capitalize"><b>{{ticket?.index_number?('00000' +
                ticket?.index_number).slice(-6):'Loading..'}}</b> -
              <penji-ticket-cate-item [ticket_cate_id]="ticket.ticket_sub_category_id"></penji-ticket-cate-item>
            </p>
          </a>
        </li>
      </ng-container>
      <!-- No data -->
      <ng-container
        *ngIf="!isLoading && search_control.value !== '' && vm.list_user.length === 0 && vm.list_project.length === 0 && vm.list_ticket.length === 0">
        <nz-empty class="height-empty px-4 py-5" nzNotFoundImage="simple"></nz-empty>
      </ng-container>
    </ng-container>
    <!-- loading -->
    <ng-container *ngIf="isLoading">
      <ng-container *ngFor="let loading of [].constructor(6)">
        <div class="skeleton-item p-2">
          <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
        </div>
      </ng-container>
    </ng-container>
    <!-- Suggestion -->
    <ng-container *ngIf="suggestion">
      <ng-container *ngIf="suggest_search$ | async as suggest_search">
        <li nz-menu-item class="cursor-default text-uppercase font-bold color-purple">Recent searches:</li>
        <ng-container *ngFor="let item of suggest_search">
          <li nz-menu-item>
            <a class="px-4 py-2 d-block" (click)="clickSuggest(item)">
              <p class="m-0 text-capitalize">
                <span [innerHTML]="item.title"></span> - <span
                  [class]="item?.type === 'member' ? 'color-pink' : item?.type === 'customer' ? 'color-purple' : item?.type === 'project' ? 'color-orange': ''">{{item.type}}</span>
              </p>
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</nz-dropdown-menu>
