import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TeamService } from '@penji/shared/data-access';
import * as firebase from 'firebase/firestore';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { EMPTY, map, Observable } from 'rxjs';

@Component({
  selector: 'penji-customer-threemonth-icon',
  standalone: true,
  imports: [CommonModule, NzToolTipModule],
  templateUrl: './customer-threemonth-icon.component.html',
  styleUrls: ['./customer-threemonth-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerThreemonthIconComponent {

  @Input() client_team_id?: string;

  teamSv = inject(TeamService);
  final_90$: Observable<any|undefined> | undefined;

  ngOnChanges(): void {
    if(this.client_team_id){
      this.final_90$ = this.teamSv.getTeamDetail(this.client_team_id).pipe(
        map(rs=>{
          if(rs&&rs.created_at){
            const now = firebase.Timestamp.now().toDate().getTime();
            const temp90 = now - 90 * 24 * 60 * 60 * 1000;
            const final90_temp = firebase.Timestamp.fromDate(new Date(temp90));
            const final_90 = rs.created_at >= final90_temp ? true : false;
            return final_90;
          }else{
            return EMPTY;
          }
        })
      );
    }
  }

}
