import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { RequestOff, RequestOffOld } from '../models/request-off.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CheckinOld } from '../models/checkin.model';

@Injectable({
    providedIn: 'root'
})
export class RequestOffService extends LogService<RequestOff>{

    datePipe = inject(DatePipe);
    async listRequestOff(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string) {
        try {
            let query: Query = this.afs.firestore.collection('request_off_v2');

            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }

            query = query.orderBy('created_at', 'desc');

            if (start_after) {
                const doc = await this.afs.firestore.doc(`request_off_v2/${start_after}`).get();
                query = query.startAfter(doc).limit(limit);
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`request_off_v2/${end_before}`).get();
                query = query.endBefore(doc).limitToLast(limit);
            } else {
                query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list: Array<RequestOff> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as RequestOff;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    listRequestOffRealTime(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: QueryDocumentSnapshot<DocumentData>,
        end_before?: QueryDocumentSnapshot<DocumentData>) {
        try {
            let query: Query = this.afs.firestore.collection('request_off_v2');

            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }

            query = query.orderBy('created_at', 'desc');

            if (start_after) {
                query = query.startAfter(start_after);
                if (limit != -1) query = query.limit(limit);
            } else if (end_before) {
                query = query.endBefore(end_before);
                if (limit != -1) query = query.limitToLast(limit);
            } else {
                if (limit != -1) query = query.limit(limit);
            }

            return new Observable<RequestOff[]>(observ => {
                query.onSnapshot(querySnapshot => {
                    const list: Array<RequestOff> = [];
                    querySnapshot.forEach((doc: any) => {
                        const data = doc.data() as RequestOff;
                        data.id = doc.id;
                        data.doc = doc;
                        list.push(data);
                    });
                    return observ.next(list);
                })
            });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }

    getRequestOffDetail(requestoff_id: string) {
        return this.afs.doc<RequestOff>(`request_off_v2/${requestoff_id}`).valueChanges({ idField: 'id' });
    }

    addRequestOff(requestoff: RequestOff) {
        return this.afs.firestore.collection(`request_off_v2`).add({ ...requestoff }).then(rs => {
            this.log_model.action = 'create';
            this.log_model.data = { ... new RequestOff, ...requestoff };
            this.createLog();
            return ({ flag: true, message: 'Request off successfully added!', data: { ...requestoff, id: rs.id } });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message, data: {} as RequestOff });
        });
    }

    updateRequestOff(requestoff: RequestOff) {
        return this.afs.firestore.doc(`request_off_v2/${requestoff.id}`).update({ ...requestoff }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ... new RequestOff, ...requestoff };
            this.createLog();
            return ({ flag: true, message: 'Request off successfully updated!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    deleteRequestOff(requestoff_id: string) {
        return this.afs.firestore.doc(`request_off_v2/${requestoff_id}`).delete().then(() => {
            this.log_model.action = 'delete';
            this.log_model.data = { ... new RequestOff, requestoff_id: requestoff_id } as RequestOff;
            this.createLog();
            return ({ flag: true, message: 'Request off successfully deleted!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    // add, edit, delete request off old ver =========================== start
    async addRequestOffVer5(requestoff: RequestOff) {
        const __checkin_id = this.datePipe.transform((requestoff.off_at.seconds) * 1000, 'yyyy-MM-dd') as string;
        const data = new RequestOffOld();
        data.uid = requestoff.uid;
        data.approved = requestoff.approved == 1 ? true : false;
        data.created_at = requestoff.created_at;
        data.date_off = __checkin_id;
        data.pto = requestoff.pto == 1 ? true : false;
        data.reason = requestoff.reason;
        data.view = requestoff.approved == 0 ? false : true;
        const nope_off = await this.checkDateRequestOffNotExistVer5(requestoff.uid, __checkin_id);
        if (nope_off) {
            return this.afs.firestore.collection(`user/${requestoff.uid}/request_off`).add({ ...data }).then(rs => {
                this.log_model.action = 'create';
                this.log_model.data = { ... new RequestOff, ...requestoff };
                this.createLog();
                return ({ flag: true, message: 'Request off successfully added!', data: { ...requestoff, id: requestoff.uid + '_' + rs.id } });
            }).catch((err: any) => {
                return ({ flag: false, message: err.message, data: {} as RequestOff });
            });
        } else {
            return ({ flag: false, message: 'Date off already exists!', data: {} as RequestOff });
        }
    }

    // can be update if approved == 0
    async updateRequestOffVer5(requestoff: RequestOff) {
        const _key_arr = requestoff.id.trim().split('_');
        if (_key_arr.length > 1) {
            const __uid = _key_arr[0];
            const __requestoff_id = _key_arr[1];
            const __checkin_id = this.datePipe.transform((requestoff.off_at.seconds) * 1000, 'yyyy-MM-dd') as string;
            // batch for update
            const batch = this.afs.firestore.batch();
            let tempRef;
            // update request off
            const data = new RequestOffOld();
            data.uid = __uid;
            data.approved = requestoff.approved == 1 ? true : false;
            data.created_at = requestoff.created_at;
            data.date_off = __checkin_id;
            data.pto = requestoff.pto == 1 ? true : false;
            data.reason = requestoff.reason;
            data.view = requestoff.approved == 0 ? false : true;
            tempRef = this.afs.firestore.doc(`user/${__uid}/request_off/${__requestoff_id}`);
            batch.update(tempRef, { ...data });
            // add checkin by request off
            if (requestoff.approved == 1) {
                const checkin = new CheckinOld();
                checkin.approved = requestoff.approved == 1 ? true : false;
                checkin.clock_in = null;
                checkin.clock_out = null;
                checkin.created_at = requestoff.off_at;
                checkin.new_project = 0;
                checkin.off = true;
                checkin.pto = requestoff.pto == 1 ? true : false;
                checkin.revision_project = 0;
                checkin.time_work = 0;
                checkin.total_point = 0;
                checkin.uid = __uid;
                tempRef = this.afs.firestore.doc(`user/${__uid}/checkin/${__checkin_id}`);
                batch.set(tempRef, { ...checkin });
            }
            return await batch.commit().then(() => {
                this.log_model.action = 'update';
                this.log_model.data = { ... new RequestOff, ...requestoff };
                this.createLog();
                return ({ flag: true, message: 'Request off successfully updated!', data: { ...requestoff } });
            }).catch((err: any) => {
                return ({ flag: false, message: err.message, data: {} as RequestOff });
            });
        } else {
            return this.updateRequestOff(requestoff);
        }
    }

    // can be delete if approved != 1
    async deleteRequestOffVer5(requestoff_id: string) {
        const _key_arr = requestoff_id.trim().split('_');
        if (_key_arr.length > 1) {
            const __uid = _key_arr[0];
            const __requestoff_id = _key_arr[1];
            return this.afs.firestore.doc(`user/${__uid}/request_off/${__requestoff_id}`).delete().then(() => {
                this.log_model.action = 'delete';
                this.log_model.data = { ... new RequestOff, id: __requestoff_id } as RequestOff;
                this.createLog();
                return ({ flag: true, message: 'Request off successfully deleted!' });
            }).catch((err: any) => {
                return ({ flag: false, message: err.message });
            });
        } else {
            return this.deleteRequestOff(requestoff_id);
        }
    }

    async checkDateRequestOffNotExistVer5(uid: string, date_off: string) {
        try {
            const collection = this.afs.collection(`user/${uid}/request_off`, ref => {
                return ref.where('date_off', '==', date_off).limit(1);
            });
            const result = await collection.get().toPromise() as any;
            console.log(result);
            if (result.size > 0) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    // add, edit, delete request off old ver =========================== end
}
