import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, switchMap } from 'rxjs';

export interface routerParamState {
  design_category_id: string | null,
  view_type: "projects" | "designers",
  designer_id: string | null,
  certified: string | null,
  current_project_list: Array<any>,
  current_designer_list: Array<any>,
}

export const inititialState: routerParamState = {
  design_category_id: null,
  view_type: "designers",
  designer_id: null,
  certified: null,
  current_designer_list: [],
  current_project_list: [],
};

@Injectable({ providedIn: 'root' })
export class QueryParamStoreService extends ComponentStore<routerParamState> {
   routeActive = inject(ActivatedRoute);
    constructor(

    ) {
        super(inititialState);
    }
    readonly design_category_id$ = this.select(state => state.design_category_id);
    readonly view_type$ = this.select(state => state.view_type);
    readonly designer_id$ = this.select(state => state.designer_id);
    readonly certified$ = this.select(state => state.certified);

    readonly init = this.effect((params$: Observable<{}>) => {
      return params$.pipe(
        switchMap((query_param: any) => {
          this.setState((state) => {
            return {
                ...state,
                design_category_id: query_param['category_id']!="all"?query_param['category_id']:null,
                view_type: query_param['view_type'],
                designer_id: query_param['designer_id'],
                certified: query_param['blue_tick']
            }
          })
          return EMPTY
        })
      )
    })

}

