<nz-header id="layout-header" class="relative z-20">
  <div
    class="container flex justify-between items-center pl-3 lg:pl-0 p-4 relative"
  >
    <a href="/" class="block-logo flex items-center justify-between">
      <img
        src="assets/images/layout-images/Logo-white.svg"
        alt="Category Design Banner"
        class="mr-3"
      />
      <img
        src="assets/images/layout-images/express.svg"
        alt="express"
      />
    </a>
    <penji-marketplace-shared-shared-ui-menu
      className="hidden lg:block"
      menuTheme="light"
    ></penji-marketplace-shared-shared-ui-menu>
    <span
      class="lg:hidden absolute text-white text-2xl top-4 right-4 z-10 scale-y-75 cursor-pointer hover:opacity-90"
      nz-icon
      nzType="menu"
      nzTheme="outline"
      (click)="openMenu()"
    ></span>
    <nz-drawer
      [nzClosable]="false"
      [nzVisible]="visibleMenu"
      nzPlacement="left"
      (nzOnClose)="closeMenu()"
      class="on-top"
    >
      <ng-container *nzDrawerContent>
        <div
          class="menu-wrapper-mobile bg-rich-black text-grape h-full absolute w-full overflow-auto on-top"
          style="margin: -24px"
        >
          <div class="heading pt-4 pr-4 mb-3">
            <penji-marketplace-shared-shared-ui-menu-buttons></penji-marketplace-shared-shared-ui-menu-buttons>
          </div>
          <div class="body">
            <penji-marketplace-shared-shared-ui-menu
              menuMode="inline"
              className="mobile-menu items-start"
            ></penji-marketplace-shared-shared-ui-menu>
          </div>
          <span
            class="absolute text-red text-2xl bottom-4 left-[50%] translate-x-[-50%] z-10 cursor-pointer hover:opacity-90"
            nz-icon
            nzType="close-circle"
            nzTheme="fill"
            (click)="closeMenu()"
          ></span>
        </div>
      </ng-container>
    </nz-drawer>
    <penji-marketplace-shared-shared-ui-menu-buttons
      className="hidden lg:block"
    ></penji-marketplace-shared-shared-ui-menu-buttons>
  </div>
</nz-header>
