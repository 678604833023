import { Injectable } from "@angular/core";
import { LogService } from "./log.service";
import { Category, CategoryClass } from "../models/category.model";

@Injectable({
  providedIn: 'root'
})

export class CategoryService extends LogService<Category> {

  async getAllCategoryClass() {
    const all_category_class: any[] = [];
    const all_class = await this.afs.firestore.collection('project_class').orderBy('order', 'asc').get();
    const promises = [];
    for (let index = 0; index < all_class.docs.length; index++) {
      const snapshot = all_class.docs[index];
      const class_category = snapshot.data() as CategoryClass;
      class_category.id = snapshot.id;
      all_category_class.push(class_category);
      promises.push(this.afs.firestore.collection('project_class').doc(snapshot.id).collection('project_category').get());
    }
    const all_category_promises = await Promise.all(promises);
    all_category_promises.forEach((ref,index)=>{
      ref.docs.forEach(item=>{
        const category = item.data() as Category;
        category.id = item.id;
        category.class_id = all_category_class[index].id;
        all_category_class.push(category);
      });
    });
    return all_category_class;
  }

  async updateCategory(class_id: string, category: Category){
    const batch = this.afs.firestore.batch();
    if(class_id!==category.class_id){
      batch.delete(this.afs.firestore.collection('project_class').doc(category.class_id).collection('project_category').doc(category.id));
    }
    batch.set(this.afs.firestore.collection('project_class').doc(class_id).collection('project_category').doc(category.id), {
        ...category,
        class_id: class_id
      }, {merge: true});
    return await batch.commit().then(() => {
      this.log_model.action = 'update';
      this.log_model.data = {...new Category, ...category};
      this.createLog();
      return { flag: true, message: 'Category successfully updated', data: {
        ...category,
        class_id: class_id
      } as Category}
    }).catch((error)=>{
      return ({flag: false, message: error, data: {} as Category})
    });
  }

  createCategory(class_id: string, category: Category){
    return this.afs.firestore.collection('project_class').doc(class_id).collection('project_category').add({...category}).then((rs)=>{
      this.log_model.action = 'add';
      this.log_model.data = {...new Category, ...category};
      this.createLog();
      return {flag: true, message: 'Success', data: {...category, id: rs.id} as Category}
    }).catch((error) => ({flag: false, message: error.message, data: {} as Category}))
  }
}




