<penji-element-input class="w-full" [input_type]="'squad_group'" (output_value)="inputSquadGroup($event)"></penji-element-input>
<ng-container *ngIf="list_project$ | async as list_project">
  <nz-table #table_list [nzFrontPagination]="false" [nzShowPagination]="false" [nzData]="list_project"
    nzTableLayout="fixed" [nzBordered]="true" [nzPageSize]="20" [nzSize]="'small'">
    <thead>
      <tr>
        <th>Title</th>
        <th>Status</th>
        <th>Category</th>
        <th>Assigned</th>
        <th>Created Date</th>
        <!-- <th>Action</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of table_list.data">
        <td><a [routerLink]="['/project/'+item?.id]">{{item?.title}}</a></td>
        <td><penji-project-status [project_data]="item"></penji-project-status></td>
        <td><penji-category-title [category_id]="item.project_cat_id"></penji-category-title></td>
        <td><penji-project-assigned [project_data]="item"></penji-project-assigned></td>
        <td>{{item.created_at.toDate() | date: 'mediumDate'}}</td>
        <!-- <td>
          <a [routerLink]="['/project/team/'+item?.team_id+'/project/'+item?.id]">Edit</a>
          <nz-divider nzType="vertical"></nz-divider>
          <a [routerLink]="['/project/team/'+item?.team_id+'/project/'+item?.id]">Delete</a>
        </td> -->
      </tr>
    </tbody>
  </nz-table>
</ng-container>
