import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTagModule } from 'ng-zorro-antd/tag';

@Component({
  selector: 'penji-request-off-pto',
  standalone: true,
  imports: [CommonModule, NzTagModule],
  templateUrl: './request-off-pto.component.html',
  styleUrls: ['./request-off-pto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestOffPtoComponent {
  @Input() pto: number = 0;
}
