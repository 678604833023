import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { QuestionAnswer, QuestionCategory } from '../models/template-answer.model';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Query } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class TemplateAnswerService extends LogService<any> {

  listQuestionCategory() {
    try {
      let query: Query = this.afs.firestore.collection('question_category');
      query = query.orderBy('order', 'asc');

      return query.get().then(querySnapshot => {
        const list: Array<QuestionCategory> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as QuestionCategory;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  addQuestionCategory(question_category: QuestionCategory) {
    return this.afs.collection(`question_category`).add({...question_category}).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new QuestionCategory, ...question_category};
      this.createLog();
      return ({flag: true, message: 'Question category successfully added!', data: { ...question_category, id: rs.id}});
    }).catch((err: any) => {
      return ({flag: false, message: err.message, data: {} as QuestionCategory});
    });
  }

  updateQuestionCategory(question_category: QuestionCategory) {
    delete question_category.doc;
    return this.afs.doc(`question_category/${question_category.id}`).update({...question_category}).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new QuestionCategory, ...question_category};
      this.createLog();
      return ({flag: true, message: 'Question category successfully updated!'});
    }).catch((err: any) => {
      return ({flag: false, message: err.message});
    });
  }

  deleteQuestionCategory(question_category_id: string) {
    return this.afs.doc(`question_category/${question_category_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new QuestionCategory, question_category_id: question_category_id} as QuestionCategory;
      this.createLog();
      return ({flag: true, message: 'Question category successfully deleted!'});
    }).catch((err: any) => {
      return ({flag: false, message: err.message});
    });
  }


  listQuestionAnswer(where_query?: Array<WhereQueryInterface>) {
    try {
      let query: Query = this.afs.firestore.collection('question_answer');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator , q.field_value);
        })
      }
      query = query.orderBy('created_at', 'desc');

      return query.get().then(querySnapshot => {
        const list: Array<QuestionAnswer> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as QuestionAnswer;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  addQuestionAnswer(question_answer: QuestionAnswer) {
    return this.afs.collection(`question_answer`).add({...question_answer}).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new QuestionAnswer, ...question_answer};
      this.createLog();
      return ({flag: true, message: 'Question answer successfully added!', data: { ...question_answer, id: rs.id}});
    }).catch((err: any) => {
      return ({flag: false, message: err.message, data: {} as QuestionAnswer});
    });
  }

  updateQuestionAnswer(question_answer: QuestionAnswer) {
    delete question_answer.doc;
    return this.afs.doc(`question_answer/${question_answer.id}`).update({...question_answer}).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new QuestionAnswer, ...question_answer};
      this.createLog();
      return ({flag: true, message: 'Question answer successfully updated!'});
    }).catch((err: any) => {
      return ({flag: false, message: err.message});
    });
  }

  deleteQuestionAnswer(question_answer_id: string) {
    return this.afs.doc(`question_answer/${question_answer_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new QuestionAnswer, question_answer_id: question_answer_id} as QuestionAnswer;
      this.createLog();
      return ({flag: true, message: 'Question answer successfully deleted!'});
    }).catch((err: any) => {
      return ({flag: false, message: err.message});
    });
  }
}
