import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MarketplaceShellRoutingModule } from './marketplace-shell.routing';

@NgModule({
  imports: [
    CommonModule,
    MarketplaceShellRoutingModule
  ]
})
export class MarketplaceShellModule { }
