import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTagModule } from 'ng-zorro-antd/tag';

@Component({
  selector: 'penji-overtime-approved',
  standalone: true,
  imports: [CommonModule, NzTagModule],
  templateUrl: './overtime-approved.component.html',
  styleUrls: ['./overtime-approved.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeApprovedComponent {
  @Input() approved: number = 0;
}
