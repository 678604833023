<ng-container *ngIf="data$ | async as list_project">
    <nz-table #table_list [nzFrontPagination]="false" [nzShowPagination]="false" [nzData]="list_project"
      nzTableLayout="fixed" [nzBordered]="true" [nzPageSize]="20" [nzSize]="'small'" [nzLoading]="isLoading$ | async">
      <thead>
        <tr>
          <th>Title</th>
          <th>Status</th>
          <th>Category</th>
          <th>Designer Team</th>
          <th>Assigned</th>
          <th>Created Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of table_list.data">
          <td><a [routerLink]="['/project/'+item?.id]">{{item?.title}}</a></td>
          <td><penji-project-status [project_data]="item"></penji-project-status></td>
          <td><penji-category-title [category_id]="item.project_cat_id"></penji-category-title></td>
          <td><penji-designer-team-name [designer_team_id]="item.assign_team_designer"></penji-designer-team-name></td>
          <td><penji-project-assigned [project_data]="item"></penji-project-assigned></td>
          <td>{{item.created_at.toDate() | date: 'mediumDate'}}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>