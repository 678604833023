import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@penji/shared/environments';

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
})
export class DataAccessModule {}
