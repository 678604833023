import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestOff, UserData } from '@penji/shared/data-access';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AuthService, AuthStore } from '@penji/shared/auth/data-access';
import { RequestOffStoreService } from '@penji/team/request-off/data-access';
import * as firebase from 'firebase/firestore';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { differenceInCalendarDays } from 'date-fns';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ModalGeneralAssignComponent } from '@penji/team/shared/shared-ui/modal-general-assign';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';

@Component({
  selector: 'penji-request-off-create-update',
  standalone: true,
  imports: [
    CommonModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    NzSelectModule,
    UserItemComponent
  ],
  templateUrl: './request-off-create-update.component.html',
  styleUrls: ['./request-off-create-update.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestOffCreateUpdateComponent {
  @Input() form_type?: string = 'add';
  @Input() requestoff_data?: RequestOff;
  loading: boolean = false;
  fb = inject(FormBuilder);
  ref = inject(NzModalRef);
  modal = inject(NzModalService);
  authSV = inject(AuthService);

  validateForm!: UntypedFormGroup;
  today: Date = new Date();
  disabledDayOff = (current: Date): boolean => differenceInCalendarDays(current, this.today) < 0;

  memberDetail$ = new BehaviorSubject<UserData | any>(null);

  constructor(
    private requestoffStore: RequestOffStoreService,
  ) {
    this.validateForm = this.fb.group({
      id: [""],
      created_at: [null],
      off_at: [null],
      off_at_tmp: [null, [Validators.required]],
      pto: [2],
      reason: ["", [Validators.required]],
      approved: [0],
      uid: ["", [Validators.required]],
      why_unapproved: [""],
    });
  }

  ngOnInit() {
    if (this.form_type == 'add') {
      this.validateForm.patchValue({
        created_at: firebase.Timestamp.now(),
        off_at_tmp: (new Date(firebase.Timestamp.now().seconds * 1000)),
        uid: this.requestoff_data?.uid ? this.requestoff_data?.uid : null
      });
    } else if (this.form_type == 'edit') {
      this.validateForm.patchValue({
        id: this.requestoff_data?.id ? this.requestoff_data?.id : "",
        created_at: this.requestoff_data?.created_at ? this.requestoff_data?.created_at : null,
        off_at_tmp: this.requestoff_data?.off_at ? (new Date(this.requestoff_data?.off_at.seconds * 1000)) : null,
        pto: this.requestoff_data?.pto ? this.requestoff_data?.pto : 0,
        reason: this.requestoff_data?.reason ? this.requestoff_data?.reason : "",
        approved: this.requestoff_data?.approved ? this.requestoff_data?.approved : 0,
        uid: this.requestoff_data?.uid ? this.requestoff_data?.uid : "",
        why_unapproved: this.requestoff_data?.why_unapproved ? this.requestoff_data?.why_unapproved : ""
      });
    }
  }

  async openReassignMember() {
    const ref: NzModalRef = this.modal.create({
      nzContent: ModalGeneralAssignComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        list_user_id: this.validateForm.get('uid')?.value ? [this.validateForm.get('uid')?.value] : [],
        role: [2, 4, 7],
        multiple_select: false
      },
      nzTitle: 'Reassign',
      nzWidth: '405px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
    const list_out_assign_by = await ref.afterClose.pipe(take(1)).toPromise();
    if (list_out_assign_by && list_out_assign_by.length > 0) {
      this.validateForm.patchValue({ uid: list_out_assign_by[0] });
      const member = await this.authSV.getUserDetail(list_out_assign_by[0]);
      if (member)
        this.memberDetail$.next(member);
      else
        this.memberDetail$.next(null);
    }
  }

  onSubmit() {
    this.loading = true;
    this.validateForm.patchValue({
      off_at: this.validateForm.controls['off_at_tmp'].value ?
        firebase.Timestamp.fromDate(this.validateForm.controls['off_at_tmp'].value) : null
    });
    // remove Date format, just keep Timestamp format
    this.validateForm.removeControl('off_at_tmp');
    if (this.form_type == 'edit') {
      this.requestoffStore.updateRequestOff$(this.validateForm.value);
      this.loading = false;
    } else {
      this.requestoffStore.addRequestOff$(this.validateForm.value);
      this.loading = false;
    }
    this.ref.destroy();
  }
}
