<div [ngClass]="['relative', container_class_name]">
  <input nz-input #input [formControl]="control" [placeholder]="placeholder" [ngClass]="input_class_name" />

  <div *ngIf="showClearButton" class="absolute z-20 right-2 top-1/2 -translate-y-1/2">
    <svg-icon key="close" [fontSize]="icon_size" class="text-gray-300 hover:text-gray-400 cursor-pointer"
      (click)="clear()"> </svg-icon>
  </div>
  <div *ngIf="icon && !showClearButton" class="absolute z-10 right-2 top-1/2 -translate-y-1/2">
    <svg-icon [key]="icon" [fontSize]="icon_size" class="text-gray-400"> </svg-icon>
  </div>
</div>
