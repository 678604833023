import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NzMenuModeType,
  NzMenuModule,
  NzMenuThemeType,
} from 'ng-zorro-antd/menu';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'penji-marketplace-shared-shared-ui-menu',
  standalone: true,
  imports: [CommonModule, NzMenuModule, RouterModule],
  templateUrl: './marketplace-shared-shared-ui-menu.component.html',
  styleUrls: ['./marketplace-shared-shared-ui-menu.component.scss'],
})
export class MarketplaceSharedSharedUiMenuComponent {
  @Input() className = '';
  @Input() menuMode: NzMenuModeType = 'horizontal';
  @Input() menuTheme: NzMenuThemeType = 'dark';
}
