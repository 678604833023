import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { DesignerTeam } from '@penji/shared/data-access';
import { DesignerTeamStoreService } from '@penji/team/designer-team/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-designer-team-name',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './designer-team-name.component.html',
  styleUrls: ['./designer-team-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignerTeamNameComponent {

  @Input() designer_team_id!: string;
  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  designer_team$!: Observable<DesignerTeam>;

  ngOnChanges() {
    if (this.designer_team_id) {
      this.designer_team$ = this.DesignerTeamStoreService.getOneDesignerTeam(this.designer_team_id);
    }
  }
}
