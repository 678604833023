import { TemplateAnswerService, QuestionCategory, QuestionAnswer } from '@penji/shared/data-access';
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Params } from '@angular/router';

interface TemplateAnswerState extends EntityState<any> {
  loading: boolean,
  error: string,
  list_question_category: QuestionCategory[]
}

const adapter = createEntityAdapter<any>();
const initialState: TemplateAnswerState = adapter.getInitialState({
  loading: false,
  error: '',
  list_question_category: []
})
const { selectAll } = adapter.getSelectors();

@Injectable({
  providedIn: 'root'
})
export class TemplateAnswerStoreService extends ComponentStore<TemplateAnswerState> {

  TemplateAnswerSV = inject(TemplateAnswerService)

  constructor() { super(initialState); }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);
  list_question_category$ = this.select((s) => s.list_question_category);

  getQuestionCategoryById(question_category_id: string) {
    return this.list_question_category$.pipe(
      map(list => {
        const index = list.findIndex(q => q.id == question_category_id);
        return list[index];
      })
    )
  }

  getFilterQuestionAnswer(params: Params) {
    return this.select(this.data$,
      (rs) => {
        let temp = [...rs] as QuestionAnswer[];
        for (let k in params) {
          temp = temp.filter((t: any) => t[k] == params[k]);
        }
        return temp.sort((a: any, b:any)=>( a.created_at < b.created_at)?1:-1);
      }
    );
  }

  loadQuestionCategory$ = this.effect(none$ => {
    return none$.pipe(
      switchMap(() => {
        return this.select((s) => {
          if (!s.list_question_category || s.list_question_category.length <=0) {
            return this.TemplateAnswerSV.listQuestionCategory();
          } else {
            return of([]);
          }
        });
      }),
      switchMap(rs => rs),
      map((final_list: QuestionCategory[]) => {
        if (final_list && final_list.length > 0) {
          this.patchState({ list_question_category: final_list });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  createQuestionCategory$ = this.effect((question_answer$: Observable<QuestionCategory>) => {
    return question_answer$.pipe(
      mergeMap((question_answer: QuestionCategory) => {
        return this.TemplateAnswerSV.addQuestionCategory(question_answer);
      }),
      map(rs => {
        if (rs.flag) {
          this.patchState(s => {
            s.list_question_category.push(rs.data);
            return s;
          });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateQuestionCategory$ = this.effect((question_category$: Observable<QuestionCategory>) => {
    return question_category$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((question_category: QuestionCategory) => {
        return combineLatest(this.TemplateAnswerSV.updateQuestionCategory(question_category), of(question_category));
      }),
      map(([rs, question_category]) => {
        if (rs.flag) {
          this.patchState(s => {
            const index = s.list_question_category.findIndex(q => q.id == question_category.id);
            if (index > 0) {
              s.list_question_category[index] = question_category;
            }
            return s;
          });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteQuestionCategory$ = this.effect((question_category_id$: Observable<string>) => {
    return question_category_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((question_category_id: string) => {
        return combineLatest(this.TemplateAnswerSV.deleteQuestionCategory(question_category_id), of(question_category_id));
      }),
      map(([rs, question_category_id]) => {
        if (rs.flag) {
          this.patchState(s => {
            const index = s.list_question_category.findIndex(q => q.id == question_category_id);
            if (index > 0) {
              s.list_question_category.splice(index, 1);
            }
            return s;
          });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  loadQuestionAnswer$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
      }),
      switchMap(() => {
        return this.TemplateAnswerSV.listQuestionAnswer();
      }),
      map((final_list: QuestionAnswer[]) => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  createQuestionAnswer$ = this.effect((question_answer$: Observable<QuestionAnswer>) => {
    return question_answer$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((question_answer: QuestionAnswer) => {
        return this.TemplateAnswerSV.addQuestionAnswer(question_answer);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateQuestionAnswer$ = this.effect((question_answer$: Observable<QuestionAnswer>) => {
    return question_answer$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((question_answer: QuestionAnswer) => {
        return combineLatest(this.TemplateAnswerSV.updateQuestionAnswer(question_answer), of(question_answer));
      }),
      map(([rs, question_answer]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({id: question_answer.id, changes: question_answer}, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteQuestionAnswer$ = this.effect((question_answer_id$: Observable<string>) => {
    return question_answer_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((question_answer_id: string) => {
        return combineLatest(this.TemplateAnswerSV.deleteQuestionAnswer(question_answer_id), of(question_answer_id));
      }),
      map(([rs, question_answer_id]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(question_answer_id, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

}
