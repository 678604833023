import { Injectable, inject } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { Checkin, CheckinOld } from '../models/checkin.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { MemberService } from './member.service';
import { take } from 'rxjs';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CheckinService extends LogService<Checkin>{

  memberSV = inject(MemberService);
  datePipe = inject(DatePipe);

  async listCheckin(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      let query: Query = this.afs.firestore.collection('checkin_v2');
      if (where_query && where_query.length > 0) {
        where_query.forEach(async q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        const doc = await this.afs.firestore.doc(`checkin_v2/${start_after}`).get();
        query = query.startAfter(doc).limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`checkin_v2/${end_before}`).get();
        query = query.endBefore(doc).limitToLast(limit);
      } else {
        query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Checkin> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Checkin;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  getCheckinDetail(checkin_id: string) {
    return this.afs.doc<Checkin>(`checkin_v2/${checkin_id}`).valueChanges({ idField: 'id' });
  }

  addCheckin(checkin: Checkin) {
    return this.afs.firestore.collection(`checkin_v2`).add({ ...checkin }).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new Checkin, ...checkin };
      this.createLog();
      return ({ flag: true, message: 'Checkin successfully added!', data: { ...checkin, id: rs.id } });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message, data: {} as Checkin });
    });
  }

  updateCheckin(checkin: Checkin) {
    delete checkin.doc;
    return this.afs.firestore.doc(`checkin_v2/${checkin.id}`).update({ ...checkin }).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new Checkin, ...checkin };
      this.createLog();
      return ({ flag: true, message: 'Checkin successfully updated!', data: { ...checkin } });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message, data: null });
    });
  }

  deleteCheckin(checkin_id: string) {
    return this.afs.firestore.doc(`checkin_v2/${checkin_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new Checkin, ticket_id: checkin_id } as Checkin;
      this.createLog();
      return ({ flag: true, message: 'Checkin successfully deleted!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }

  async findCheckinToday(uid: string) {
    try {
      const collection = this.afs.collection(`checkin_v2`, ref => {
        return ref.where('uid', '==', uid)
          .where('created_at', '>=', firebase.Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0))))
          .where('created_at', '<=', firebase.Timestamp.fromDate(new Date(new Date().setHours(23, 59, 59))))
          .limit(1);
      });
      const result = await collection.get().toPromise();
      if (result && result.size > 0) {
        const data = result.docs[0].data() as Checkin;
        data.id = result.docs[0].id;
        return data;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  // add, edit, delete checkin old ver =========================== start
  async addCheckinVer5(checkin: Checkin) {
    const __checkin_id = this.datePipe.transform((firebase.Timestamp.now().seconds) * 1000, 'yyyy-MM-dd') as string;
    const user = await this.afs.collection(`user/`).doc(checkin.uid).valueChanges().pipe(take(1)).toPromise() as any;
    const find = await this.afs.collection(`user/${checkin.uid}/checkin`).doc(__checkin_id).valueChanges().pipe(take(1)).toPromise();
    if (!find) {
      const data = new CheckinOld();
      data.uid = checkin.uid;
      data.clock_in = checkin.checkin_at ? checkin.checkin_at : null;
      data.clock_out = checkin.checkout_at ? checkin.checkout_at : null;
      data.created_at = checkin.created_at;
      data.new_project = 0;
      data.off = checkin.pto == 0 ? false : true;
      data.pto = checkin.pto == 1 ? true : false;
      data.revision_project = 0;
      data.time_work = 0;
      data.total_point = 0;
      data.over_time = false;
      data.note = checkin.note ? checkin.note : '';
      data.log_type = checkin.log_type ? checkin.log_type : '';
      // for lateness
      const now = new Date((new Date(checkin.checkin_at.seconds * 1000)).toLocaleString("en-US", {
        timeZone: user.user_info.time_zone ? user.user_info.time_zone : 'Asia/Manila'
      }));
      const day = now.getDay();
      if (user.schedule && user.schedule[day].start_time) {
        const date = now.getDate();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();
        const start_at_string = year + '-' + month + '-' + date + ' ' + user.schedule[day].start_time;
        const start_at = new Date(start_at_string);
        const minutes = this.minutesDiff(start_at, now);
        if (minutes > 30) {
          data.lateness = true;
        } else {
          data.lateness = false;
        }
      } else {
        data.lateness = false;
      }
      // end for lateness
      return this.afs.firestore.doc(`user/${checkin.uid}/checkin/${__checkin_id}`).set({ ...data }).then(rs => {
        console.log(rs);
        this.log_model.action = 'create';
        this.log_model.data = { ... new Checkin, ...checkin };
        this.createLog();
        return ({ flag: true, message: 'Checkin successfully added!', data: { ...checkin, id: checkin.uid + '_' + __checkin_id } });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message, data: {} as Checkin });
      });
    } else {
      return ({ flag: false, message: 'Checkin already exists!', data: {} as Checkin });
    }
  }

  async updateCheckinVer5(checkin: Checkin) {
    const _key_arr = checkin.id.trim().split('_');
    if (_key_arr.length > 1) {
      const __uid = _key_arr[0];
      const __checkin_id = _key_arr[1];
      const user = await this.afs.collection(`user/`).doc(__uid).valueChanges().pipe(take(1)).toPromise() as any;
      // convert data checkin to checkinold
      const data = new CheckinOld();
      data.uid = __uid;
      data.clock_in = checkin.checkin_at ? checkin.checkin_at : null;
      data.clock_out = checkin.checkout_at ? checkin.checkout_at : null;
      data.created_at = checkin.created_at;
      data.new_project = 0;
      data.off = checkin.pto == 0 ? false : true;
      data.pto = checkin.pto == 1 ? true : false;
      data.revision_project = 0;
      data.time_work = ((checkin.checkout_at.seconds - checkin.checkin_at.seconds) / 3600) | 0;
      data.total_point = 0;
      data.over_time = false;
      data.note = checkin.note ? checkin.note : '';
      data.log_type = checkin.log_type ? checkin.log_type : '';
      // for lateness
      const now = new Date((new Date(checkin.checkin_at.seconds * 1000)).toLocaleString("en-US", {
        timeZone: user.user_info.time_zone ? user.user_info.time_zone : 'Asia/Manila'
      }));
      const day = now.getDay();
      if (user.schedule && user.schedule[day].start_time) {
        const date = now.getDate();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();
        const start_at_string = year + '-' + month + '-' + date + ' ' + user.schedule[day].start_time;
        const start_at = new Date(start_at_string);
        const minutes = this.minutesDiff(start_at, now);
        if (minutes > 30) {
          data.lateness = true;
        } else {
          data.lateness = false;
        }
      } else {
        data.lateness = false;
      }
      // end for lateness
      return this.afs.firestore.doc(`user/${__uid}/checkin/${__checkin_id}`).update({ ...data }).then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ... new Checkin, ...checkin };
        this.createLog();
        return ({ flag: true, message: 'Checkin successfully updated!', data: { ...checkin } });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message, data: null });
      });
    } else {
      return this.updateCheckin(checkin);
    }
  }

  async deleteCheckinVer5(checkin_id: string) {
    const _key_arr = checkin_id.trim().split('_');
    if (_key_arr.length > 1) {
      const __uid = _key_arr[0];
      const __checkin_id = _key_arr[1];
      return this.afs.firestore.doc(`user/${__uid}/checkin/${__checkin_id}`).delete().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new Checkin, id: checkin_id } as Checkin;
        this.createLog();
        return ({ flag: true, message: 'Checkin successfully deleted!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    } else {
      return this.deleteCheckin(checkin_id);
    }
  }

  minutesDiff(start_daytime: Date, end_daytime: Date) {
    let difference_value = ((end_daytime.getTime() - start_daytime.getTime()) / 1000) / 60;
    return Math.round(difference_value);
  }
  // add, edit, delete checkin old ver =========================== end
}
