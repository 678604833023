import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@penji/marketplace/shared/shared-ui/layout';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'portfolio'
      },
      {
        path: 'portfolio',
        loadComponent: () =>
          import('@penji/marketplace/portfolio/portfolio-ui/portfolio-page').then(
            (mod) => mod.PortfolioPageComponent
          ),
      },
    ],
  },
  {
    path:'checkout',
    loadComponent: () => import('@penji/marketplace-checkout/ui/layout').then(mod => mod.MarketplaceCheckoutUiLayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('@penji/marketplace-checkout/pages/default').then(mod => mod.StepsComponent),
      }
    ]
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class MarketplaceShellRoutingModule {}
