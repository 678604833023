<div class="container mx-auto my-5" *ngIf="authProfile$ | async as authProfile">
  <h1 style="display: flex; line-height: 32px; font-size: 20px; font-weight: 600">
    Request Off
    <a (click)="addRequestOff()"
      class="hover:border-blue-500 hover:border-solid hover:bg-white text-blue-400 group flex flex-col items-center justify-center border border-dashed border-blue-300 text-sm p-1 rounded-full w-8 h-8 ml-3"><svg-icon fontSize="16px" key="plus"></svg-icon>
    </a>
  </h1>
  <div class="filter-option">
    <div class="flex py-5">
      <penji-element-input
        class="w-full"
        [input_type]="'requestoff_date'"
        [input_selected]="
          action['start_at']
            ? [action['start_at'] | date, action['end_at'] | date]
            : null
        "
        (output_value)="inputDate($event)"
      ></penji-element-input>
      <penji-element-input
        class="w-full"
        [input_type]="'requestoff_pto'"
        [input_selected]="action['pto']"
        (output_value)="inputPTO($event)"
      ></penji-element-input>
      <penji-element-input
        class="w-full"
        [input_type]="'requestoff_approved'"
        [input_selected]="action['approved']"
        (output_value)="inputApproved($event)"
      ></penji-element-input>
    </div>
  </div>
  <!-- <nz-alert class="mb-2" *ngIf="error$ | async as error" nzType="error" [nzMessage]="error" nzShowIcon></nz-alert> -->
  <ng-container *ngIf="list_request_off$ | async as list_request_off">
    <nz-table
      #table_list
      [nzData]="list_request_off"
      [nzBordered]="true"
      [nzPageSize]="20"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzSize]="'small'"
    >
      <thead>
        <tr>
          <th>Request Off ID</th>
          <th>UID</th>
          <th>Date Off</th>
          <th>PTO / UPTO</th>
          <th>Created At</th>
          <th>Approved / Unapproved</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of table_list.data">
          <td><a [routerLink]="['./request-off/detail/' + item?.id]">{{ item?.id }}</a></td>
          <td><penji-user-item [user_name]="true" [user_id]="item.uid" user_size="sm"></penji-user-item></td>
          <td>{{ item.off_at ? (item.off_at.toDate() | date : 'mediumDate') : 'N/A' }}</td>
          <td><penji-request-off-pto [pto]="item.pto"></penji-request-off-pto></td>
          <td>{{ item.created_at.toDate() | date : 'mediumDate' }}</td>
          <td><penji-request-off-approved [approved]="item.approved" ></penji-request-off-approved></td>
          <td>
            <a (click)="approvedRequestOff(item.id)" title="Approved">
              <svg-icon key="plus"></svg-icon>
            </a>
            <nz-divider nzType="vertical"></nz-divider>
            <a (click)="unapprovedRequestOff(item.id)" title="Unapproved">
              <svg-icon key="cancel-circle"></svg-icon>
            </a>
            <nz-divider nzType="vertical"></nz-divider>
            <a (click)="editRequestOff(item)" title="Edit">
              <svg-icon key="edit"></svg-icon>
            </a>
            <nz-divider nzType="vertical"></nz-divider>
            <a
              nz-popconfirm
              nzPopconfirmTitle="Are you sure delete this item?"
              (nzOnConfirm)="deleteRequestOff(item.id)"
              title="Delete"
            >
              <svg-icon key="delete"></svg-icon>
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</div>
