<form [formGroup]="settings_form">
    <nz-form-item nz-col>
        <nz-form-control>
            <ng-container *ngIf="key === 'user_id'">
                <nz-select [formControlName]="key" nzShowSearch nzAllowClear nzServerSearch
                    nzPlaceHolder="input search member" [(ngModel)]="selectedValue" [nzShowArrow]="false"
                    [nzFilterOption]="nzFilterOption" (nzOnSearch)="submitSearchUser($event)">
                    <ng-container *ngIf="list_user$ | async as list">
                        <nz-option *ngFor="let item of list"
                            [nzLabel]="item.user_info.first_name + ' ' + item.user_info.last_name"
                            [nzValue]="item.uid"></nz-option>
                    </ng-container>
                </nz-select>
            </ng-container>
            <ng-container *ngIf="key === 'designer_team_id'">
                <nz-select [formControlName]="key" nzShowSearch nzAllowClear nzPlaceHolder="input search team"
                    [(ngModel)]="selectedValue" [nzShowArrow]="false" [nzFilterOption]="nzFilterOption">
                    <ng-container *ngIf="list_designer_team$ | async as list">
                        <nz-option *ngFor="let item of list" [nzLabel]="item.team_name" [nzValue]="item.id"></nz-option>
                    </ng-container>
                </nz-select>
            </ng-container>
        </nz-form-control>
    </nz-form-item>
</form>