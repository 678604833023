<ng-container *ngIf="(ticketItem$ | async) as ticketItem">
  <ng-container *ngIf="clickable && ticket_id; else no_clickable">
      <a [ngStyle]="{'color':ticketItem.color, 'font-size':font_size}" [routerLink]="['/ticket/detail/'+ ticket_id]">
          {{ticketItem.title}}
      </a>
  </ng-container>
  <ng-template #no_clickable>
      <p [ngStyle]="{'color':ticketItem.color, 'font-size':font_size}">
          {{ ticketItem.title }}
      </p>
  </ng-template>
</ng-container>
