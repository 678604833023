import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { IconDefinition } from '@ant-design/icons-angular';
import { CaretDownOutline, CloseCircleFill, EyeFill, PictureFill } from '@ant-design/icons-angular/icons';
import { MarketplaceShellModule } from '@penji/marketplace/shell';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@penji/shared/environments';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { NgxStripeModule } from 'ngx-stripe';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
registerLocaleData(en);


const icons: IconDefinition[] = [CaretDownOutline, CloseCircleFill, EyeFill, PictureFill];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'marketplace' }),
    RouterModule,
    MarketplaceShellModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NzIconModule.forRoot(icons),
    ReactiveFormsModule,

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    NgxStripeModule.forRoot(environment.stripe_publish_key),
  ],
  providers   : [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
