import { Injectable, inject } from '@angular/core';
import { Overtime, OvertimeOld } from '../models/overtime.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CheckinOld } from '../models/checkin.model';

@Injectable({
    providedIn: 'root'
})
export class OvertimeService extends LogService<Overtime>{

    datePipe = inject(DatePipe);
    async listOvertime(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string) {
        try {
            let query: Query = this.afs.firestore.collection('overtime_v2');

            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }

            query = query.orderBy('created_at', 'desc');

            if (start_after) {
                const doc = await this.afs.firestore.doc(`overtime_v2/${start_after}`).get();
                query = query.startAfter(doc).limit(limit);
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`overtime_v2/${end_before}`).get();
                query = query.endBefore(doc).limitToLast(limit);
            } else {
                query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list: Array<Overtime> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as Overtime;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    listOvertimeRealTime(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: QueryDocumentSnapshot<DocumentData>,
        end_before?: QueryDocumentSnapshot<DocumentData>) {
        try {
            let query: Query = this.afs.firestore.collection('overtime_v2');

            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }

            query = query.orderBy('created_at', 'desc');

            if (start_after) {
                query = query.startAfter(start_after);
                if (limit != -1) query = query.limit(limit);
            } else if (end_before) {
                query = query.endBefore(end_before);
                if (limit != -1) query = query.limitToLast(limit);
            } else {
                if (limit != -1) query = query.limit(limit);
            }

            return new Observable<Overtime[]>(observ => {
                query.onSnapshot(querySnapshot => {
                    const list: Array<Overtime> = [];
                    querySnapshot.forEach((doc: any) => {
                        const data = doc.data() as Overtime;
                        data.id = doc.id;
                        data.doc = doc;
                        list.push(data);
                    });
                    return observ.next(list);
                })
            });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }

    getOvertimeDetail(overtime_id: string) {
        return this.afs.doc<Overtime>(`overtime_v2/${overtime_id}`).valueChanges({ idField: 'id' });
    }

    addOvertime(overtime: Overtime) {
        return this.afs.firestore.collection(`overtime_v2`).add({ ...overtime }).then(rs => {
            this.log_model.action = 'create';
            this.log_model.data = { ... new Overtime, ...overtime };
            this.createLog();
            return ({ flag: true, message: 'Overtime successfully added!', data: { ...overtime, id: rs.id } });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message, data: {} as Overtime });
        });
    }

    updateOvertime(overtime: Overtime) {
        return this.afs.firestore.doc(`overtime_v2/${overtime.id}`).update({ ...overtime }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ... new Overtime, ...overtime };
            this.createLog();
            return ({ flag: true, message: 'Overtime successfully updated!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    deleteOvertime(overtime_id: string) {
        return this.afs.firestore.doc(`overtime_v2/${overtime_id}`).delete().then(() => {
            this.log_model.action = 'delete';
            this.log_model.data = { ... new Overtime, overtime_id: overtime_id } as Overtime;
            this.createLog();
            return ({ flag: true, message: 'Overtime successfully deleted!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    // add, edit, delete overtime old ver =========================== start
    async addOvertimeVer5(overtime: Overtime) {
        const __checkin_id = this.datePipe.transform((overtime.overtime_at.seconds) * 1000, 'yyyy-MM-dd') as string;
        const nope_overtime = await this.checkDateOvertimeNotExistVer5(overtime.uid, __checkin_id);
        if (nope_overtime) {
            const data = new OvertimeOld();
            data.uid = overtime.uid;
            data.view = overtime.approved == 0 ? false : true;
            data.approved = overtime.approved == 1 ? true : false;
            data.created_at = overtime.created_at;
            data.date_over_time = __checkin_id;
            data.link_over_time = { ...overtime.overtime_link };
            data.why_unapproved = overtime.why_unapproved ? overtime.why_unapproved : '';
            data.log_type = overtime.log_type ? overtime.log_type : '';
            return this.afs.firestore.collection(`user/${overtime.uid}/over_time`).add({ ...data }).then(rs => {
                this.log_model.action = 'create';
                this.log_model.data = { ... new Overtime, ...overtime };
                this.createLog();
                return ({ flag: true, message: 'Overtime successfully added!', data: { ...overtime, id: overtime.uid + '_' + rs.id } });
            }).catch((err: any) => {
                return ({ flag: false, message: err.message, data: {} as Overtime });
            });
        } else {
            return { flag: false, message: 'Overtime date already exists!', data: {} as Overtime };
        }

    }

    // can be update if approved == 0
    async updateOvertimeVer5(overtime: Overtime) {
        const _key_arr = overtime.id.trim().split('_');
        if (_key_arr.length > 1) {
            const __uid = _key_arr[0];
            const __overtime_id = _key_arr[1];
            const __checkin_id = this.datePipe.transform((overtime.overtime_at.seconds) * 1000, 'yyyy-MM-dd') as string;
            // batch for update
            const batch = this.afs.firestore.batch();
            let tempRef;
            // update overtime
            const data = new OvertimeOld();
            data.uid = overtime.uid;
            data.view = overtime.approved == 0 ? false : true;
            data.approved = overtime.approved == 1 ? true : false;
            data.created_at = overtime.created_at;
            data.date_over_time = __checkin_id;
            data.link_over_time = { ...overtime.overtime_link };
            data.why_unapproved = overtime.why_unapproved ? overtime.why_unapproved : '';
            data.log_type = overtime.log_type ? overtime.log_type : '';
            tempRef = this.afs.firestore.doc(`user/${__uid}/over_time/${__overtime_id}`);
            batch.update(tempRef, { ...data });
            // add checkin by request off
            if (overtime.approved == 1) {
                const checkin = new CheckinOld();
                checkin.approved = overtime.approved == 1 ? true : false;
                checkin.clock_in = null;
                checkin.clock_out = null;
                checkin.created_at = overtime.overtime_at;
                checkin.new_project = 0;
                checkin.revision_project = 0;
                checkin.time_work = 0;
                checkin.total_point = 0;
                checkin.uid = __uid;
                tempRef = this.afs.firestore.doc(`user/${__uid}/checkin/${__checkin_id}`);
                batch.set(tempRef, { ...checkin });
            }
            return await batch.commit().then(() => {
                this.log_model.action = 'update';
                this.log_model.data = { ... new Overtime, ...overtime };
                this.createLog();
                return ({ flag: true, message: 'Overtime successfully updated!', data: { ...overtime } });
            }).catch((err: any) => {
                return ({ flag: false, message: err.message, data: {} as Overtime });
            });
        } else {
            return this.updateOvertime(overtime);
        }
    }

    // can be delete if approved != 1
    deleteOvertimeVer5(overtime_id: string) {
        const _key_arr = overtime_id.trim().split('_');
        if (_key_arr.length > 1) {
            const __uid = _key_arr[0];
            const __overtime_id = _key_arr[1];
            return this.afs.firestore.doc(`user/${__uid}/over_time/${__overtime_id}`).delete().then(() => {
                this.log_model.action = 'delete';
                this.log_model.data = { ... new Overtime, id: __overtime_id } as Overtime;
                this.createLog();
                return ({ flag: true, message: 'Overtime successfully deleted!' });
            }).catch((err: any) => {
                return ({ flag: false, message: err.message });
            });
        } else {
            return this.deleteOvertime(overtime_id);
        }
    }

    async checkDateOvertimeNotExistVer5(uid: string, date_off: string) {
        try {
            const collection = this.afs.collection(`user/${uid}/over_time`, ref => {
                return ref.where('date_over_time', '==', date_off).limit(1);
            });
            const result = await collection.get().toPromise() as any;
            console.log(result);
            if (result.size > 0) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    // add, edit, delete overtime old ver =========================== end
}
