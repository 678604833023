import { inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/firestore';
import { take } from 'rxjs';
import { CustomerAdvice } from '../models/customer-advice.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerAdvicesService extends LogService<CustomerAdvice> {

  afa = inject(AngularFireAuth);
  getListCustomerAdvices(customer_id: string, limit = 20, last_id?: string) {
    console.log(customer_id);
    let query = this.afs.firestore
      .collection('customer_advices')
      .where('customer_id', '==', customer_id)
      .orderBy('created_at', 'desc');
    if (last_id) {
      query = query.startAfter(
        this.afs.firestore.doc(`customer_advices/${last_id}`)
      );
    }
    query = query.limit(limit);
    return query.get().then((snapshot) => {
      const list: CustomerAdvice[] = [];
      snapshot.forEach((item) => {
        const data = item.data() as CustomerAdvice;
        data.id = item.id;
        list.push(data);
      });
      return list;
    });
  }
  async createCustomerAdvice(cus_advice: CustomerAdvice) {
    const auth = await this.afa.authState.pipe(take(1)).toPromise();
    const advice = { ...cus_advice, owner_id: auth?.uid , created_at: firebase.Timestamp.now() };
    return this.afs.firestore
      .collection('customer_advices')
      .add(advice)
      .then((res) => {
        this.log_model.action = 'create';
        this.log_model.data = {...new CustomerAdvice , ...advice};
        this.createLog();
        return { flag: true, message: 'success', data: { ...advice, id: res.id} };
      })
      .catch((err) => ({ flag: false, message: err.message , data: {} as CustomerAdvice}));
  }
  updateCustomerAdvice(cus_advice: CustomerAdvice) {
    return this.afs
      .collection('customer_advices')
      .doc(cus_advice.id)
      .update({ ...cus_advice })
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = {...new CustomerAdvice , ...cus_advice};
        this.createLog();
        return { flag: true, message: 'success', data: cus_advice as CustomerAdvice };
      })
      .catch((err) => ({ flag: false, message: err.message, data: {} as CustomerAdvice }));
  }
  deleteCustomerAdvice(id: string) {
    return this.afs
      .collection('customer_advices')
      .doc(id)
      .delete()
      .then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new CustomerAdvice, id: id};
        this.createLog();
        return { flag: true, message: 'success', data: {} as CustomerAdvice};
      })
      .catch((err) => ({ flag: false, message: err.message, data: {} as CustomerAdvice }));
  }
}
