import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { RequestOff, RequestOffService, WhereQueryInterface } from '@penji/shared/data-access';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthStore } from '@penji/shared/auth/data-access';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ConvertTimezonePipe } from '@penji/shared/pipes';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { RequestOffPtoComponent } from '@penji/team/request-off/request-off-ui/request-off-pto';
import { RequestOffApprovedComponent } from '@penji/team/request-off/request-off-ui/request-off-approved';
import { RouterModule } from '@angular/router';
import { RequestOffCreateUpdateComponent } from '@penji/team/request-off/request-off-ui/request-off-create-update';
import { InputComponent } from '@penji/shared/ui/element/input';
import * as firebase from 'firebase/firestore';

@Component({
  selector: 'penji-w-request-off',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    RouterModule,
    NzButtonModule,
    NzAlertModule,
    NzIconModule,
    ConvertTimezonePipe,
    NzPopoverModule,
    SvgIconComponent,
    NzPopconfirmModule,
    UserItemComponent,
    RequestOffPtoComponent,
    RequestOffApprovedComponent
  ],
  templateUrl: './w-request-off.component.html',
  styleUrls: ['./w-request-off.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WRequestOffComponent {

  @Input() limit = -1;

  requestOffSV = inject(RequestOffService);
  modal = inject(NzModalService);
  authStore = inject(AuthStore);

  authProfile$ = this.authStore.authProfile$;
  list_request_off$!: Observable<RequestOff[]>;

  test$ = new BehaviorSubject<{}>({});
  action: any = {};
  constructor() {
    this.list_request_off$ = this.test$.pipe(switchMap((action:any)=>{
      this.action = {...action};
      const where_query: Array<WhereQueryInterface> = [];
      let start_after;
      let end_before;
      if (action) {
        for (const k in action) {
          if (k == 'limit') {
            this.limit = action[k];
          } else if (k == 'start_after') {
            start_after = action[k];
          } else if (k == 'end_before') {
            end_before = action[k];
          } else if (k == 'start_at') {
            where_query.push({
              field_name: 'created_at',
              field_operator: '>=',
              field_value: firebase.Timestamp.fromDate(new Date(new Date(action[k]).setHours(0, 0, 0)))
            });
          } else if (k == 'end_at') {
            where_query.push({
              field_name: 'created_at',
              field_operator: '<=',
              field_value: firebase.Timestamp.fromDate(new Date(new Date(action[k]).setHours(23, 59, 59)))
            });
          } else if (k == 'pto' || k == 'approved') {
            where_query.push({
              field_name: k,
              field_operator: '==',
              field_value: parseInt(action[k])
            });
          } else {
            where_query.push({
              field_name: k, field_operator: '==',
              field_value: action[k] == 'true' ? true : action[k] == 'false' ? false : action[k]
            });
          }
        }
      }
      return this.requestOffSV.listRequestOffRealTime(this.limit, where_query, start_after, end_before);
    }));
  }

  inputDate(term: Date[]) {
    let temp: any = this.test$.getValue();
    if (term.length > 0) {
      temp['start_at'] = (term[0].getMonth() + 1) + '-' + term[0].getDate() + '-' + term[0].getFullYear();
      temp['end_at'] = (term[1].getMonth() + 1) + '-' + term[1].getDate() + '-' + term[1].getFullYear();
    } else {
      delete temp['start_at'];
      delete temp['end_at'];
    }
    delete temp['start_after'];
    delete temp['end_before'];
    this.test$.next(temp);
  }
  inputPTO(term: string) {
    let temp: any = this.test$.getValue();
    if (term != '') {
      temp['pto'] = term;
    } else {
      delete temp['pto'];
    }
    delete temp['start_after'];
    delete temp['end_before'];
    this.test$.next(temp);
  }
  inputApproved(term: string) {
    let temp: any = this.test$.getValue();
    if (term != '') {
      temp['approved'] = term;
    } else {
      delete temp['approved'];
    }
    delete temp['start_after'];
    delete temp['end_before'];
    this.test$.next(temp);
  }


  addRequestOff() {
    const ref: NzModalRef = this.modal.create({
      nzContent: RequestOffCreateUpdateComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        form_type: 'add'
      },
      nzWidth: '550px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
  }

  editRequestOff(request_off: RequestOff) {
    const ref: NzModalRef = this.modal.create({
      nzContent: RequestOffCreateUpdateComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        form_type: 'edit',
        requestoff_data: request_off
      },
      nzWidth: '550px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
  }

  deleteRequestOff(request_off_id: string) {
    const rs = this.requestOffSV.deleteRequestOff(request_off_id);
    console.log(rs);
  }

  approvedRequestOff(request_off_id: string){
    let request_off = new RequestOff();
    request_off.id = request_off_id;
    request_off.approved = 1;
    this.requestOffSV.updateRequestOff(request_off);
  }

  unapprovedRequestOff(request_off_id: string){
    let request_off = new RequestOff();
    request_off.id = request_off_id;
    request_off.approved = 2;
    this.requestOffSV.updateRequestOff(request_off);
  }
}
