import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'penji-marketplace-shared-shared-ui-menu-buttons',
  standalone: true,
  imports: [CommonModule, NzButtonModule],
  templateUrl: './marketplace-shared-shared-ui-menu-buttons.component.html',
  styleUrls: ['./marketplace-shared-shared-ui-menu-buttons.component.scss'],
})
export class MarketplaceSharedSharedUiMenuButtonsComponent {
  @Input() className = '';
}
