import { Timestamp } from "@angular/fire/firestore";

export type genericAction = 'create' | 'add' | 'update' | 'delete' | 'remove' | 'change status' | 'upload' | 'upload_design';

export class Log<T> {
  id?: string;
  created_at?: Timestamp;
  by_user_id?: string;
  action: genericAction | undefined;
  data: T | undefined
  expand?: boolean
}
