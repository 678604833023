import { DocumentReference } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';


export class Comment {
  id?: string;
  id_link?: string;
  content?: string;
  created_at?: Timestamp;
  updated_at?: Timestamp;
  removed?: boolean;
  number_index?: number;
  owner_id?: string;
  owner_ref?: DocumentReference;
  marker?: any;
  comment_parent_id?: string | null;
  log_type = "comment";
}

