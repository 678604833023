import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
  selector: 'penji-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SvgIconComponent,
    NzInputModule
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
  @Input() control = new FormControl('');
  @Input() container_class_name = '';
  @Input() input_class_name = '';
  @Input() icon = '';
  @Input() icon_size = '';
  @Input() placeholder = '';
  @Input() enable_clear_button = false;
  @Input() auto_focus = false;
  @ViewChild('input') inputRef!: ElementRef;

  get showClearButton(): boolean {
    return this.enable_clear_button && this.control?.value ? true : false;
  }

  clear() {
    this.control.patchValue('');
    this.inputRef.nativeElement.focus();
  }

  ngAfterViewInit() {
    if(this.auto_focus && this.inputRef) {
      this.inputRef.nativeElement.focus();
    }
  }
}
