/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DahboardStoreService } from '@penji/team/dashboard/data-access';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { SettingsFormComponent } from './settings-form/settings-form.component';
import { of } from 'rxjs';
import { Widget } from '@penji/shared/data-access';

@Component({
  selector: 'penji-w-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    SettingsFormComponent
  ],
  templateUrl: './w-form.component.html',
  styleUrls: ['./w-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WFormComponent {
  @Input() widget_data: Widget | undefined;
  ref = inject(NzModalRef);
  store = inject(DahboardStoreService);
  widget_form = new UntypedFormGroup({
    cols: new UntypedFormControl(2, [Validators.required]),
    rows: new UntypedFormControl(2, [Validators.required])
  });
  ngOnInit(): void {
    if (this.widget_data) {
      this.widget_form.patchValue({
        cols: this.widget_data.cols ?? 2,
        rows: this.widget_data.rows ?? 2
      })
    }
  }
  onChangeForm() {
    // console.log({ ...this.widget_form.value })
    const data = { ...this.widget_data, ...this.widget_form.value } as Widget;
    console.log({ ...this.widget_data, ...this.widget_form.value })
    this.store.addWidget$(of({ ...data }));
    this.ref.destroy();
  }
}
