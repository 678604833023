
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { HeaderComponent } from '@penji/marketplace/shared/shared-ui/header';
import { FooterComponent } from '@penji/marketplace/shared/shared-ui/footer';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'penji-layout',
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {

}
