<form nzLayout="vertical" nz-form [formGroup]="widget_form" (ngSubmit)="onChangeForm()">
    <div class="widget">
        <nz-form-item nz-col>
            <nz-form-label class="text-15">Cols<span class="text-red-500">*</span></nz-form-label>
            <nz-form-control nzErrorTip="Please input cols!">
                <input type="number" nz-input nzSize="large" class="rounded-sm text-14 text-gray-500"
                    formControlName="cols" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col>
            <nz-form-label class="text-15">Rows<span class="text-red-500">*</span></nz-form-label>
            <nz-form-control nzErrorTip="Please input rows!">
                <input type="number" nz-input nzSize="large" class="rounded-sm text-14 text-gray-500"
                    formControlName="rows" />
            </nz-form-control>
        </nz-form-item>
        <ng-container *ngIf="widget_data?.settings">
            <penji-settings-form [formGroup]="widget_form" [settings]="widget_data?.settings"></penji-settings-form>
        </ng-container>
        <button type="submit" nz-button nzType="primary" nzBlock>Submit</button>
    </div>
</form>