import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Project } from '@penji/shared/data-access';

@Component({
  selector: 'penji-project-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusComponent {

  PROJECT_STATUS = {
    in_queue: 'in queue', // in_queue = true
    new: 'new', // 1
    question: 'question', // 1.5 , status_addition = 3
    started: 'started', // 0, status_addition = 1
    revision: 'revision', // 2
    to_verify: 'to verify', // 3
    scheduled_verify: 'scheduled verify', // verify_progress = true
    reject: 'reject', // 3.5, status_addition = 2
    delivered: 'delivered', // 4
    on_hold: 'on hold', // 5
    completed: 'completed', // 6
    draft: 'draft', // 7
    deleted: 'deleted', // 8
    pause: 'pause' // pause = true
  }
  @Input() project_data!: Project;

  result_status: string = '';
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.project_data) {
      if (this.project_data.status === 8) {
        this.result_status = this.PROJECT_STATUS.deleted;
      } else {
        if (this.project_data.in_queue) {
          this.result_status = this.PROJECT_STATUS.in_queue;
        } else if (this.project_data.pause) {
          this.result_status = this.PROJECT_STATUS.pause;
        } else {
          if (this.project_data.pending_review) {
            if (this.project_data.verify_progress) {
              this.result_status = this.PROJECT_STATUS.scheduled_verify;
            } else {
              this.result_status = this.PROJECT_STATUS.to_verify;
            }
          } else {
            if (!this.project_data.status_addition || this.project_data.status_addition == 0) {
              if (this.project_data.status === 1) {
                this.result_status = this.PROJECT_STATUS.new;
              } else if (this.project_data.status === 2) {
                this.result_status = this.PROJECT_STATUS.revision;
              } else if (this.project_data.status === 4) {
                this.result_status = this.PROJECT_STATUS.delivered;
              } else if (this.project_data.status === 5) {
                this.result_status = this.PROJECT_STATUS.on_hold;
              } else if (this.project_data.status === 6) {
                this.result_status = this.PROJECT_STATUS.completed;
              } else if (this.project_data.status === 7) {
                this.result_status = this.PROJECT_STATUS.draft;
              }
            } else {
              if (this.project_data.status_addition === 1) {
                this.result_status = this.PROJECT_STATUS.started;
              } else if (this.project_data.status_addition === 2) {
                this.result_status = this.PROJECT_STATUS.reject;
              } else if (this.project_data.status_addition === 3) {
                this.result_status = this.PROJECT_STATUS.question;
              }
            }
          }
        }
      }
    }
  }
}
