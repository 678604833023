<div
  class="flex items-center justify-between {{ (className && className) || '' }}"
>
  <a href="https://login.penji.co"
    nz-button
    nzType="link"
    nzSize="large"
    class="text-white text-base font-lab-bold"
    >Login</a
  >
  <a href="https://penji.co/view-a-demo-video"
    nz-button
    nzSize="large"
    class="!bg-transparent border-2 border-white border-solid text-white rounded-md text-base font-lab-bold"
  >
    Get a Demo
  </a>
</div>
