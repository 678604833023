
<ng-container *ngIf="image_url">
  <ng-container *ngIf="image_auto_fit; else no_fit">
    <img
      nz-image
      [nzDisablePreview]="true"
      [nzSrc]="image_url"
      alt="penji"
      class="bg-white rounded-lg"
    />
  </ng-container>
  <ng-template #no_fit>
    <div style="background-image: url({{image_url}});"></div>
  </ng-template>
  <a *ngIf="clickable" class="absolute inset-0 z-50" (click)="openImage()"></a>
</ng-container>
