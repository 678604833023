import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { MarketplaceWordpressService } from '@penji/shared/data-access';
import { EMPTY, catchError, map, switchMap, take, tap } from 'rxjs';

interface FAQState extends EntityState<any> {
  loading: boolean,
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: FAQState = adapter.getInitialState({
  loading: false,
  error: ''
})
const { selectAll } = adapter.getSelectors();

@Injectable({
  providedIn: 'root'
})
export class FaqStoreService extends ComponentStore<FAQState> {

  constructor(private marketPlaceSv: MarketplaceWordpressService) {
    super(initialState);
  }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);

  getOneFaqById(faq_id: string) {
    return this.select((s) => s.entities[faq_id]);
  }
  getOneFaqBySlug(faq_slug: string) {
    return this.select(
      this.data$,
      (rs) => rs.filter((f) => f.slug == faq_slug)[0]
    );
  }

  loadAllFaq$ = this.effect((params$) => {
    return params$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap(() => {
        return this.marketPlaceSv.listAll('faq').pipe(
          take(1),
          map(rs => rs)
        );
      }),
      map(list => {
        if (list && list.length > 0) {
          const temp = [...list];
          this.setState((state) => adapter.setAll(temp, state));
        }
        this.patchState({ loading: false });
      }),
      catchError(rs => {
        console.log(rs);
        this.patchState({ loading: false, error: rs.error.code });
        return EMPTY;
      })
    );
  });
}
