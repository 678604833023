import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, combineLatest, distinct, map, mergeMap } from "rxjs";
import * as userActions from './user.action';
import { UserService } from "../../services/user.service";
import { UserSimple } from "../../models/user.model";
import { CheckinService } from "../../services/checkin.service";

@Injectable()
export class UserEffect {

  actions = inject(Actions);
  userSv = inject(UserService);
  checkinSV = inject(CheckinService);

  loadUser$ = createEffect(() =>  this.actions.pipe(
    ofType(userActions.loadUserDB),
    distinct(e => e.uid),
    mergeMap((action: any) => {
      if(action && action.uid)
        return combineLatest(this.userSv.getUserDetail(action.uid), this.checkinSV.findCheckinToday(action.uid));
      return EMPTY;
    }),
    map(([user, checkin]) => {
      if(user)
      {
        const user_t = new UserSimple;
        user_t.id = user.uid;
        user_t.uid = user.uid;
        user_t.avatar = user.user_info.avatar ? user.user_info.avatar : '';
        user_t.email = user.user_info.email ? user.user_info.email : '';
        user_t.first_name = user.user_info.first_name ? user.user_info.first_name : '';
        user_t.last_name = user.user_info.last_name ? user.user_info.last_name : '';
        user_t.full_name = user.user_info.full_name ? user.user_info.full_name : '';
        user_t.role = user.role ? user.role : -1;
        user_t.client_team_id = user.team_active ?? '';
        if (checkin) {
          if (checkin.checkin_at) {
            user_t.checkin_status = 3;
            if (checkin.lateness) {
              user_t.checkin_status = 4;
            }
            user_t.checkin_at = checkin.checkin_at;
          }
          if (checkin.checkout_at) {
            user_t.checkin_status = 5;
          }
          if (checkin.pto && checkin.pto != 0) {
            user_t.checkin_status = checkin.pto;
          }
        } else {
          user_t.checkin_status = 0;
        }

        return userActions.createUser({user:user_t as UserSimple});
      }
      return { type:''};
    })
  ))

}

