/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Project, WProjectTodoService } from '@penji/shared/data-access';
import { Observable, tap, switchMap, map } from 'rxjs';

interface ProjectState extends EntityState<Project> {
    isLoading: boolean;
}
const adapter = createEntityAdapter<Project>();
const initialState: ProjectState = adapter.getInitialState({ isLoading: false });

@Injectable()
export class WProjectTodoStoreService extends ComponentStore<ProjectState> {
    constructor(private projectSV: WProjectTodoService) {
        super(initialState)
    }
    readonly data$ = this.select(s => {
        const list_project = Object.values(s.entities);
        return list_project.sort((a: any, b: any) => (a.created_at < b.created_at) ? 1 : -1) as Project[];
    });
    readonly isLoading$ = this.select(s => s.isLoading);
    loadProjects$ = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            tap(() => this.patchState({ isLoading: true })),
            switchMap((params: any) => {
                return this.projectSV.getListProjectActive(params.user_id, params.designer_team_id);
            }),
            map(list => {
                this.setState(state => adapter.setAll(list, state));
                this.patchState({ isLoading: false });
                return list;
            })
        );
    })
}
