import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { Brand } from '../models/brand.model';


@Injectable({
  providedIn: 'root',
})
export class BrandService extends LogService<Brand> {

  getAllCustomerBrand(team_id: string) {
    return  this.afs.firestore.collection('team').doc(team_id).collection('libraries').get().then((snapshot:any) => {
      const list = Array<Brand>();
      snapshot.forEach((doc:any) => {
        const brand = doc.data() as Brand;
        brand.id = doc.id;
        brand.team_id = team_id;
        list.push(brand);
      });
      return list;
    })
  }
  addBrand(brand: Brand){
    return this.afs.firestore.collection('team').doc(brand.team_id).collection('libraries').add({...brand})
    .then((rs)=> {
      this.log_model.action = 'add';
      this.log_model.data = {...new Brand, ...brand};
      this.createLog();
      const brand_temp = {...brand} as Brand;
      brand_temp.id = rs.id;
      return { flag: true, message: 'Brand successfully added!', data: brand_temp}
    })
    .catch(error=> ({ flag: false, message: error.message, data: {} as Brand }));
  }
  updateBrand(brand: Brand){
    return this.afs.firestore.collection('team').doc(brand.team_id).collection('libraries').doc(brand.id).update({...brand})
    .then(()=> {
      this.log_model.action = 'update';
      this.log_model.data = {...new Brand, ...brand};
      this.createLog();
      return { flag: true, message: 'Brand successfully updated!' };
    })
    .catch(error=> ({ flag: false, message: error.message }));
  }
  deleteBrand(brand: Brand){
    return this.afs.firestore.collection('team').doc(brand.team_id).collection('libraries').doc(brand.id).delete()
    .then(()=> {
      this.log_model.action = 'delete';
      this.log_model.data = {...new Brand, ...brand};
      this.createLog();
      return { flag: true, message: 'Brand successfully deleted!' }
    })
    .catch(error=> ({ flag: false, message: error.message }));
  }
}
