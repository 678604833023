import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as categoryClassActions from './category-class.action';
import { map, mergeMap, switchMap } from "rxjs";
import { Category, CategoryService } from '@penji/shared/data-access';

@Injectable()
export class CategoryClassEffect {

  categorySV = inject(CategoryService);
  actions = inject(Actions);

  loadAllCategoryClass$ = createEffect(()=> this.actions.pipe(
    ofType(categoryClassActions.loadCateAndClassDB),
    switchMap(() => this.categorySV.getAllCategoryClass()),
    map(all_class=>{
      if(all_class)
        return categoryClassActions.createCateAndClass({category_class: all_class});
      return categoryClassActions.nothing();
    })
  ));
  updateCategory$ = createEffect(()=> this.actions.pipe(
    ofType(categoryClassActions.updateCategoryDB),
    mergeMap((action)=> this.categorySV.updateCategory(action.class_id, { ... new Category, ...action.update.changes})),
    map(rs=>{
      if(rs)
        return categoryClassActions.updateCategory({update: { id: rs.data.id?rs.data.id:'', changes: rs.data} })
      return categoryClassActions.nothing();
    })
  ));
  createCategory$ = createEffect(()=>this.actions.pipe(
    ofType(categoryClassActions.createCategoryDB),
    mergeMap((action) => this.categorySV.createCategory(action.class_id, action.category)),
    map(rs=>{
      if(rs)
        return categoryClassActions.createCategorySuccess({category: rs.data});
      return categoryClassActions.nothing();
    })
  ))
}
