<ng-container [ngSwitch]="input_type">
    <div *ngSwitchCase="'keyword'" class="w-full pr-3 mb-6 md:mb-0">
      <input #search_keyword (input)="inputValue(search_keyword.value)" type="text" name="search" id="search"
        class="block rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="search everything here" [value]="input_selected? input_selected: ''">
    </div>
    <div *ngSwitchCase="'user_type'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        User Type
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a person"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Designer" nzValue="4"></nz-option>
          <nz-option nzLabel="Team Lead" nzValue="2"></nz-option>
          <nz-option nzLabel="Supporter" nzValue="7"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'date-picker'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Date {{input_selected}}
      </label>
      <div class="relative">
        <nz-range-picker class="w-full" [(ngModel)]="input_selected"
          (ngModelChange)="inputDateValue($event)"></nz-range-picker>
      </div>
    </div>
    <div *ngSwitchCase="'membership'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Membership
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a membership"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Active" nzValue="active"></nz-option>
          <nz-option nzLabel="Past Due" nzValue="past due"></nz-option>
          <nz-option nzLabel="Canceled" nzValue="canceled"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'addon'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Add-on
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a add-on"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Unknown" nzValue="unknown"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'coupon'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Promo Code
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a promo code"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Unknown" nzValue="unknown"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'designer_team'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Designer Team
      </label>
      <div class="relative">
        <nz-select nzShowSearch nzAllowClear class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a designer team"
          (ngModelChange)="inputValue($event)">
          <nz-option *ngFor="let item of (list$ | async)" [nzLabel]="item?.team_name" [nzValue]="item?.id"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'question_category'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Question Category
      </label>
      <div class="relative">
        <nz-select nzShowSearch nzAllowClear class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a question category"
          (ngModelChange)="inputValue($event)">
          <nz-option *ngFor="let item of (list$ | async)" [nzLabel]="item?.title" [nzValue]="item?.id"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'industry'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Industry
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a industry"
          (ngModelChange)="inputValue($event)">
          <nz-option-group *ngFor="let item of list_industries" [nzLabel]="item.key">
            <nz-option *ngFor="let str of item.value" [nzValue]="str" [nzLabel]="str"></nz-option>
          </nz-option-group>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'country'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Country
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a country"
          (ngModelChange)="inputValue($event)">
          <nz-option *ngFor="let item of country" [nzLabel]="item.name" [nzValue]="item.value"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'status'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Status
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a status"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="New" nzValue="1"></nz-option>
          <nz-option nzLabel="Revison" nzValue="2"></nz-option>
          <nz-option nzLabel="Delivered" nzValue="4"></nz-option>
          <nz-option nzLabel="On-hold" nzValue="5"></nz-option>
          <nz-option nzLabel="Completed" nzValue="6"></nz-option>
          <nz-option nzLabel="Draft" nzValue="7"></nz-option>
          <nz-option nzLabel="Deleted" nzValue="8"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'team_type'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Team Type
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" ngModel nzAllowClear nzPlaceHolder="Select a type" (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Designer Team" nzValue="1"></nz-option>
          <nz-option nzLabel="Support Team" nzValue="2"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'media_type'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Media Type
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" ngModel nzAllowClear nzPlaceHolder="Select a type" (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Images" nzValue="image"></nz-option>
          <nz-option nzLabel="Documents" nzValue="file"></nz-option>
          <nz-option nzLabel="Fonts" nzValue="font"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'affiliate_type'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Type
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" ngModel nzAllowClear nzPlaceHolder="Select a type" (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Client" nzValue="client"></nz-option>
          <nz-option nzLabel="Affiliate" nzValue="affiliate"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'promo_code'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Promotion code
      </label>
      <div class="relative">
        <input nz-input type="text" class="w-full" [(ngModel)]="input_selected" ngModel nzPlaceHolder="Promotion code" (ngModelChange)="inputValue($event)"/>
      </div>
    </div>
    <div *ngSwitchCase="'squad_group'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Squad Group
      </label>
      <div class="relative">
        <nz-select nzShowSearch nzAllowClear class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a squad group"
          (ngModelChange)="inputValue($event)">
          <nz-option *ngFor="let item of (list$ | async)" [nzLabel]="item?.name" [nzValue]="item?.id"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'category'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Category
      </label>
      <div class="relative">
        <nz-select nzShowSearch nzAllowClear class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a category"
          (ngModelChange)="inputValue($event)">
          <nz-option *ngFor="let item of (list$ | async)" [nzLabel]="item?.title" [nzValue]="item?.id"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'type_complete'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Project complete type
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select project complete type"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Positive" nzValue="2"></nz-option>
          <nz-option nzLabel="Neutral" nzValue="7"></nz-option>
          <nz-option nzLabel="Negative" nzValue="4"></nz-option>
        </nz-select>
      </div>
    </div>
    <!-- checkin / checkout -->
    <div *ngSwitchCase="'checkin_date'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Date
      </label>
      <div class="relative">
        <nz-range-picker class="w-full" [(ngModel)]="input_selected"
          (ngModelChange)="inputDateValue($event)"></nz-range-picker>
      </div>
    </div>
    <div *ngSwitchCase="'checkin_lateness'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Lateness
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select lateness"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Yes" [nzValue]="'true'"></nz-option>
          <nz-option nzLabel="No" [nzValue]="'false'"></nz-option>
        </nz-select>
      </div>
    </div>
    <!-- request off -->
    <div *ngSwitchCase="'requestoff_date'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Date
      </label>
      <div class="relative">
        <nz-range-picker class="w-full" [(ngModel)]="input_selected"
          (ngModelChange)="inputDateValue($event)"></nz-range-picker>
      </div>
    </div>
    <div *ngSwitchCase="'requestoff_pto'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        PTO / UPTO
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select pto"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="PTO" [nzValue]="1"></nz-option>
          <nz-option nzLabel="UPTO" [nzValue]="2"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'requestoff_approved'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Approved / Unapproved
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select approved"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Approved" [nzValue]="1"></nz-option>
          <nz-option nzLabel="Unapproved" [nzValue]="2"></nz-option>
        </nz-select>
      </div>
    </div>
    <!-- overtime -->
    <div *ngSwitchCase="'overtime_date'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Date
      </label>
      <div class="relative">
        <nz-range-picker class="w-full" [(ngModel)]="input_selected"
          (ngModelChange)="inputDateValue($event)"></nz-range-picker>
      </div>
    </div>
    <div *ngSwitchCase="'overtime_approved'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Approved / Unapproved
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select approved"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Approved" [nzValue]="'true'"></nz-option>
          <nz-option nzLabel="Unapproved" [nzValue]="'false'"></nz-option>
        </nz-select>
      </div>
    </div>
    <!-- ticket -->
    <div *ngSwitchCase="'ticket_status'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Status
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a status"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Unclaimed" [nzValue]="1"></nz-option>
          <nz-option nzLabel="Claimed" [nzValue]="2"></nz-option>
          <nz-option nzLabel="Solved" [nzValue]="3"></nz-option>
          <nz-option nzLabel="Duplicated" [nzValue]="4"></nz-option>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'ticket_date'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Date
      </label>
      <div class="relative">
        <nz-range-picker class="w-full" [(ngModel)]="input_selected"
          (ngModelChange)="inputDateValue($event)"></nz-range-picker>
      </div>
    </div>
    <div *ngSwitchCase="'ticket_main_category_id'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Main Category
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a main category"
          (ngModelChange)="inputValue($event)">
          <ng-container *ngFor="let item of (list$ | async)">
            <nz-option [nzLabel]="item?.title" [nzValue]="item?.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'ticket_sub_category_id'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Sub Category
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a sub category"
          (ngModelChange)="inputValue($event)">
          <ng-container *ngFor="let item of (list$ | async)">
            <nz-option [nzLabel]="item?.title" [nzValue]="item?.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>
    <div *ngSwitchCase="'ticket_source'" class="w-full {{show_padding ? 'pr-3' : ''}} mb-6 md:mb-0">
      <label *ngIf="show_title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
        Source
      </label>
      <div class="relative">
        <nz-select class="w-full" [(ngModel)]="input_selected" nzAllowClear nzPlaceHolder="Select a source"
          (ngModelChange)="inputValue($event)">
          <nz-option nzLabel="Automatic (trigger)" [nzValue]="1"></nz-option>
          <nz-option nzLabel="Report issue" [nzValue]="2"></nz-option>
          <nz-option nzLabel="Client app help" [nzValue]="3"></nz-option>
        </nz-select>
      </div>
    </div>
  </ng-container>
