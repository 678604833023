/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of, switchMap } from 'rxjs';
import { LogService } from './log.service';
import { environment } from '@penji/shared/environments';
import { UserData } from '../models/user.model';
import { Payment } from '../models/payment.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends LogService<UserData> {
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  nzMessageService = inject(NzMessageService);
  getListCustomerAlgolia(filter_params: any) {
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          return this.http.post(
            `${this.api_url}/filter-customer`,
            filter_params,
            httpOptions
          );
        } else {
          return of(null);
        }
      })
    );
  }
  updateCustomer(data: UserData) {
    if (data && data.user_info && data.user_info.first_name && data.user_info.last_name) {
      let keyword = data.user_info.first_name + ' ' + data.user_info.last_name;
      keyword = keyword
        .toLowerCase()
        .replace(/[.*+\-?^${}():_|[\]\\]/g, '')
        .trim();
      const key_arr = keyword.trim().split(' ');
      const key_temp = data.user_info.email
        ? [data.user_info.email.toLowerCase()]
        : [];
      key_arr.forEach((element) => {
        for (let i = 0; i < element.length; i++) {
          key_temp.push(element.substring(0, i + 1).toLowerCase());
        }
      });
      data.search_terms = key_temp;
    }
    console.log(UserData.parseObject(data));
    return this.afs
      .collection('user')
      .doc(data.uid)
      .update(UserData.parseObject(data))
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ...new UserData, ...data };
        this.createLog();
        this.nzMessageService.success('updated successfully!');
        return { flag: true, message: 'Your customer successfully updated!' };
      })
      .catch((err) => {
        console.log(err);
        this.nzMessageService.error(err.message);
        return { flag: false, message: err.message };
      });
  }
  getDetailPaymemtOfCustomer(customer_id: string) {
    return this.afs.firestore
      .collection('payment')
      .doc(customer_id)
      .get()
      .then((payment) => {
        // console.log(payment.data());
        return { ...payment.data(), id: customer_id } as Payment;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  exportCustomerData(filter_params: any) {
    const obj_filter = { ...filter_params, "type": "customer" };
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          return this.http.post(
            `http://localhost:3000/api/algolia/export-data`,
            obj_filter,
            httpOptions
          );
        } else {
          return of(null);
        }
      })
    );
  }
}
