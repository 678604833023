import { Timestamp } from '@angular/fire/firestore';

export class CustomerAdvice {
  id?: string;
  created_at?: Timestamp;
  customer_id?: string;
  owner_id?: string;
  title?: string;
  description?: string;
  for_designer?: boolean;
  log_type = 'customer_advices';
}
