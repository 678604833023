import { Media } from '@penji/shared/data-access';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import * as mediaActions from '@penji/team/media/data-access';
import * as mediaSelectors from '@penji/team/media/data-access';
import { Observable, of, tap } from 'rxjs';
import { MediaCoverComponent } from '@penji/team/media/media-ui/media-cover';

@Component({
  selector: 'penji-media-ref',
  standalone: true,
  imports: [
    CommonModule,
    MediaCoverComponent
  ],
  templateUrl: './media-ref.component.html',
  styleUrls: ['./media-ref.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaRefComponent {

  @Input() media_ref: any;
  @Input() media_size: "medium" | "thumb" | "avatar" | "original"= "medium";
  @Input() clickable = false;
  @Input() image_auto_fit = false;
  store = inject(Store);
  media$: Observable<Media> | undefined;

  ngOnChanges(): void {
    if(this.media_ref){
      this.media$ = this.store.select(mediaSelectors.getMedia(this.media_ref.id)).pipe(
        tap(rs=>{
          if(!rs){
            this.store.dispatch(mediaActions.loadMediaRefDB({user_id: this.media_ref.parent.parent.id, media_id: this.media_ref.id}));
          }
        })
      );
    }else{
      this.media$ = of();
    }
  }

}
