<div class="container mx-auto" *ngIf="authProfile$ | async as authProfile">
    <!-- <nz-alert class="mb-2" *ngIf="error$ | async as error" nzType="error" [nzMessage]="error" nzShowIcon></nz-alert> -->
    <ng-container *ngIf="list_overtime$ | async as list_overtime">
        <nz-table #table_list [nzData]="list_overtime" [nzBordered]="true" [nzPageSize]="20" [nzFrontPagination]="false"
            [nzShowPagination]="false" [nzSize]="'small'">
            <thead>
                <tr>
                    <!-- <th>Overtime ID</th> -->
                    <th>Member</th>
                    <th>Overtime At</th>
                    <th>Created At</th>
                    <th>Approved / Unapproved</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of table_list.data">
                    <!-- <td>{{item?.id}}</td> -->
                    <td><penji-user-item [user_name]="true" [user_id]="item.uid" user_size="sm"></penji-user-item></td>
                    <td>{{ item.overtime_at ? (item.overtime_at.toDate() | date:'mediumDate') : 'N/A' }}</td>
                    <td>{{ item.created_at.toDate() | date:'mediumDate' }}</td>
                    <td>
                        <penji-overtime-approved [approved]="item.approved"></penji-overtime-approved>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </ng-container>
</div>