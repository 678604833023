import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';

@Component({
  selector: 'penji-media-cover',
  standalone: true,
  imports: [CommonModule, NzImageModule],
  templateUrl: './media-cover.component.html',
  styleUrls: ['./media-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCoverComponent {

  @Input() image_url?: any;
  @Input() clickable = false;
  @Input() image_auto_fit = false;

  // @Input() set image_url(url: string | undefined) {
  //   this.background_url = url && `url(${url})`;
  // }
  // @HostBinding('style.background-image') background_url!: string | undefined;

  nzImageService = inject(NzImageService);

  openImage(){
    const images = [
      {
        src: this.image_url,
        alt: 'Penji image'
      }
    ]
    this.nzImageService.preview(images);
  }
}
