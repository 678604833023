import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Project } from '@penji/shared/data-access';
import { ProjectReassignComponent } from '@penji/team/project/project-ui/project-reassign';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';

@Component({
  selector: 'penji-project-assigned',
  standalone: true,
  imports: [
    CommonModule,
    ProjectReassignComponent,
    UserItemComponent
  ],
  templateUrl: './project-assigned.component.html',
  styleUrls: ['./project-assigned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectAssignedComponent {
  @Input() reassign_on = false;
  @Input() user_size: 'xs' | 'sm' | 'md' | 'lg' | 'xxl' = 'md';
  afs = inject(AngularFirestore)
  @Input() project_data!: Project;
}
