<div id="reassign-wrapper">
  <div nz-row class="mb-4">
    <div nz-col [nzSpan]="24">
      <penji-search [user]="true" [role]="role" [select]="true" (uid_selected)="selectUser($event)"></penji-search>
    </div>
  </div>
  <ng-container *ngIf="multiple_select; else single_select">
    <nz-checkbox-wrapper nz-row (nzOnChange)="onClickUser($event)">
      <ng-container *ngFor="let item of showing_list_user_id">
        <label nz-col [nzSpan]="24" nz-checkbox [nzValue]="item" [ngModel]="(temp_list_user_id && temp_list_user_id.includes(item)) ? true : false">
          <penji-user-item [user_id]="item" [user_name]="true" [user_avatar]="true" user_size="sm"></penji-user-item>
        </label>
      </ng-container>
    </nz-checkbox-wrapper>
  </ng-container>
  <ng-template #single_select>
    <nz-radio-group nz-row [(ngModel)]="temp_list_user_id">
      <ng-container *ngFor="let item of showing_list_user_id">
        <label nz-col [nzSpan]="24" nz-radio [nzValue]="item">
          <penji-user-item [user_id]="item" [user_name]="true" [user_avatar]="true" user_size="sm"></penji-user-item>
        </label>
      </ng-container>
    </nz-radio-group>
  </ng-template>

  <div nz-row class="py-3">
    <div nz-col [nzSpan]="24">
      <button nz-button type="button" nzType="primary" [nzBlock]="true" [disabled]="btnSubmit" (click)="onSubmit()">Save Changes</button>
    </div>
  </div>
</div>
