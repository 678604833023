import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { SearchComponent } from '@penji/team/shared/shared-ui/search';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';

@Component({
  selector: 'penji-modal-general-assign',
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    NzCheckboxModule,
    FormsModule,
    NzButtonModule,
    NzGridModule,
    NzRadioModule,
    UserItemComponent
  ],
  templateUrl: './modal-general-assign.component.html',
  styleUrls: ['./modal-general-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalGeneralAssignComponent {

  @Input() list_user_id!: string[];
  @Input() role!: number[];
  @Input() multiple_select: boolean = true;
  showing_list_user_id: string[] = [];
  temp_list_user_id!: string[] | string;
  btnSubmit = false;
  ref = inject(NzModalRef);

  ngOnInit() {
    if (this.list_user_id) {
      this.showing_list_user_id = [...this.list_user_id];
      if (this.multiple_select) {
        this.temp_list_user_id = [...this.list_user_id];
      } else {
        this.temp_list_user_id = this.list_user_id[0] ? this.list_user_id[0] : '';
      }
    }
  }

  selectUser(user_id: string) {
    if (!this.showing_list_user_id.includes(user_id)) {
      this.showing_list_user_id.push(user_id);
    }
    if (this.multiple_select) {
      if (this.temp_list_user_id && !this.temp_list_user_id.includes(user_id)) {
        if(Array.isArray(this.temp_list_user_id)) this.temp_list_user_id.push(user_id);
      }
    } else {
      this.temp_list_user_id = user_id;
    }

  }

  onClickUser(list_user_id: string[]) {
    this.temp_list_user_id = [...list_user_id];
  }

  onSubmit() {
    this.btnSubmit = true;
    if (this.multiple_select) {
      this.ref.destroy(this.temp_list_user_id);
    } else {
      this.ref.destroy([this.temp_list_user_id]);
    }
    this.btnSubmit = false;
  }
}
