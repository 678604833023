import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { RequestOff, RequestOffService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, concatMap, mergeMap, switchMap, tap, combineLatest, of, map } from 'rxjs';
import * as firebase from 'firebase/firestore';

interface RequestOffState extends EntityState<RequestOff> {
  loading: boolean,
  disable_previous: boolean,
  disable_next: boolean,
  error: string
}
const adapter = createEntityAdapter<RequestOff>();
const initialState: RequestOffState = adapter.getInitialState({
  loading: false,
  disable_previous: false,
  disable_next: false,
  error: ''
})
const { selectAll } = adapter.getSelectors();
@Injectable({
  providedIn: 'root'
})
export class RequestOffStoreService extends ComponentStore<RequestOffState>{

  limit = 20;

  RequestoffSV = inject(RequestOffService);

  constructor() { super(initialState); }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  disable_previous$ = this.select((s) => s.disable_previous);
  disable_next$ = this.select((s) => s.disable_next);
  error$ = this.select((s) => s.error);

  loadRequestOff$ = this.effect((params$: Observable<{}>) => {
    return params$.pipe(
      tap(() => {
        this.setState((state) => adapter.removeAll(state));
        this.patchState({ loading: true, disable_previous: false, disable_next: false, error: '' })
      }),
      switchMap((params: any) => {
        const where_query: Array<WhereQueryInterface> = [];
        let start_after;
        let end_before;
        if (params) {
          for (const k in params) {
            if (k == 'limit') {
              this.limit = params[k];
            } else if (k == 'start_after') {
              start_after = params[k];
            } else if (k == 'end_before') {
              end_before = params[k];
            } else if (k == 'start_at') {
              where_query.push({
                field_name: 'created_at',
                field_operator: '>=',
                field_value: firebase.Timestamp.fromDate(new Date(new Date(params[k]).setHours(0, 0, 0)))
              });
            } else if (k == 'end_at') {
              where_query.push({
                field_name: 'created_at',
                field_operator: '<=',
                field_value: firebase.Timestamp.fromDate(new Date(new Date(params[k]).setHours(23, 59, 59)))
              });
            } else {
              where_query.push({
                field_name: k, field_operator: '==',
                field_value: params[k] == 'true' ? true : params[k] == 'true' ? false : params[k]
              });
            }
          }
        }
        return this.RequestoffSV.listRequestOff(this.limit, where_query, start_after, end_before).then(rs => {
          if (rs && rs.length > 0) {
            const promise = [
              this.RequestoffSV.listRequestOff(1, where_query, undefined, rs[0].id),
              this.RequestoffSV.listRequestOff(1, where_query, rs[rs.length - 1].id, undefined)
            ]
            return combineLatest(of(rs), Promise.all(promise)).pipe(
              map(([rs, before_after]) => {
                if (before_after[0] && before_after[0].length > 0) {
                  this.patchState({ disable_previous: false });
                } else {
                  this.patchState({ disable_previous: true });
                }
                if (before_after[1] && before_after[1].length > 0) {
                  this.patchState({ disable_next: false });
                } else {
                  this.patchState({ disable_next: true });
                }
                return rs;
              })
            )
          } else {
            return of([] as RequestOff[]);
          }
        }).catch(err => {
          console.log(err);
          return of([] as RequestOff[]);
        })
      }),
      switchMap(rs => rs),
      map(final_list => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }
        if (final_list.length == 0 || final_list.length < this.limit) {
          this.patchState({ loading: false });
          if (final_list.length == 0) {
            this.patchState({ disable_previous: true, disable_next: true });
          }
        }
      }),
      catchError(err => {
        this.patchState({ loading: false });
        return EMPTY;
      })
    )
  })

  updateRequestOff$ = this.effect((RequestOff$: Observable<RequestOff>) => {
    return RequestOff$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((RequestOff: RequestOff) => {
        return combineLatest(this.RequestoffSV.updateRequestOffVer5(RequestOff), of(RequestOff));
      }),
      map(([rs, RequestOff]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({ id: RequestOff.id, changes: RequestOff }, state));
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteRequestOff$ = this.effect((requestoff_id$: Observable<string>) => {
    return requestoff_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((requestoff_id: string) => {
        return combineLatest(this.RequestoffSV.deleteRequestOffVer5(requestoff_id), of(requestoff_id));
      }),
      map(([rs, requestoff_id]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(requestoff_id, state));
          this.patchState({ loading: false });
        } else {
          console.log(rs);
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  addRequestOff$ = this.effect((RequestOff$: Observable<RequestOff>) => {
    return RequestOff$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((RequestOff: RequestOff) => {
        return this.RequestoffSV.addRequestOffVer5(RequestOff);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
}
