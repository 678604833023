export class CommonUtils {
  static industries = [
    {
      key: 'AGENCIES',
      value: [
        'Ad agency',
        'App development agency',
        'Branding agency',
        'Design agency',
        'Digital marketing agency',
        'PR agency',
        'SEO agency',
        'Social media agency',
        'Traditional marketing agency',
        'Web development agency',
        'Agency others',
      ],
    },
    {
      key: 'BUSINESSES',
      value: [
        'Accounting/Tax/Payroll',
        'Automotive',
        'Bloggers / Influencers',
        'Clothing & Apparel',
        'Colleges and Universities',
        'Consultants & coaches',
        'Contractors',
        'E-commerce',
        'Education',
        'Entertainment / Music',
        'Entrepreneur',
        'Events',
        'Food & beverages',
        'Finance',
        'Gaming / E-sports',
        'Government',
        'Health & wellness',
        'Healthcare',
        'Insurance',
        'IT / Network Services / Security',
        'Law Firms and Attorneys',
        'Marketers',
        'Merch sellers',
        'News & publications',
        'Non-profits',
        'Pharmaceutical',
        'Online businesses',
        'Printing',
        'Product & manufacturing',
        'Real estate',
        'Religious organization',
        'Restaurants and Bars',
        'Retail',
        'Software / SaaS',
        'Startup',
        'Technology',
        'Travel & tourism',
        'Venture capitalist',
        'Others',
      ],
    },
  ];
  static country = [
    { name: 'Afghanistan', value: 'AF' },
    { name: 'Albania', value: 'AL' },
    { name: 'Algeria', value: 'DZ' },
    { name: 'Andorra', value: 'AD' },
    { name: 'Angola', value: 'AO' },
    { name: 'Antigua and Barbuda', value: 'AG' },
    { name: 'Argentina', value: 'AR' },
    { name: 'Armenia', value: 'AM' },
    { name: 'Australia', value: 'AU' },
    { name: 'Austria', value: 'AT' },
    { name: 'Azerbaijan', value: 'AZ' },
    { name: 'Bahamas', value: 'BS' },
    { name: 'Bahrain', value: 'BH' },
    { name: 'Bangladesh', value: 'BD' },
    { name: 'Barbados', value: 'BB' },
    { name: 'Belarus', value: 'BY' },
    { name: 'Belgium', value: 'BE' },
    { name: 'Belize', value: 'BZ' },
    { name: 'Benin', value: 'BJ' },
    { name: 'Bhutan', value: 'BT' },
    { name: 'Bolivia', value: 'BO' },
    { name: 'Bosnia and Herzegovina', value: 'BA' },
    { name: 'Botswana', value: 'BW' },
    { name: 'Brazil', value: 'BR' },
    { name: 'Brunei', value: 'BN' },
    { name: 'Bulgaria', value: 'BG' },
    { name: 'Burkina Faso', value: 'BF' },
    { name: 'Burundi', value: 'BI' },
    { name: 'Cabo Verde', value: 'CV' },
    { name: 'Cambodia', value: 'KH' },
    { name: 'Cameroon', value: 'CM' },
    { name: 'Canada', value: 'CA' },
    { name: 'Central African Republic', value: 'CF' },
    { name: 'Chad', value: 'TD' },
    { name: 'Chile', value: 'CL' },
    { name: 'China', value: 'CN' },
    { name: 'Colombia', value: 'CO' },
    { name: 'Comoros', value: 'KM' },
    { name: 'Congo', value: 'CG' },
    { name: 'Costa Rica', value: 'CR' },
    { name: 'Côte d’Ivoire', value: 'CI' },
    { name: 'Croatia', value: 'HR' },
    { name: 'Cuba', value: 'CU' },
    { name: 'Cyprus', value: 'CY' },
    { name: 'Czechia', value: 'CZ' },
    { name: 'Denmark', value: 'DK' },
    { name: 'Djibouti', value: 'DJ' },
    { name: 'Dominica', value: 'DM' },
    { name: 'Dominican Republic', value: 'DO' },
    { name: 'DR Congo', value: 'CD' },
    { name: 'Ecuador', value: 'EC' },
    { name: 'Egypt', value: 'EG' },
    { name: 'El Salvador', value: 'SV' },
    { name: 'Equatorial Guinea', value: 'GQ' },
    { name: 'Eritrea', value: 'ER' },
    { name: 'Estonia', value: 'EE' },
    { name: 'Eswatini', value: 'SZ' },
    { name: 'Ethiopia', value: 'ET' },
    { name: 'Fiji', value: 'FJ' },
    { name: 'Finland', value: 'FI' },
    { name: 'France', value: 'FR' },
    { name: 'Gabon', value: 'GA' },
    { name: 'Gambia', value: 'GM' },
    { name: 'Georgia', value: 'GE' },
    { name: 'Germany', value: 'DE' },
    { name: 'Ghana', value: 'GH' },
    { name: 'Greece', value: 'GR' },
    { name: 'Grenada', value: 'GD' },
    { name: 'Guatemala', value: 'GT' },
    { name: 'Guinea', value: 'GN' },
    { name: 'Guinea-Bissau', value: 'GW' },
    { name: 'Guyana', value: 'GY' },
    { name: 'Haiti', value: 'HT' },
    { name: 'Holy See', value: 'VA' },
    { name: 'Honduras', value: 'HN' },
    { name: 'Hungary', value: 'HU' },
    { name: 'Iceland', value: 'IS' },
    { name: 'India', value: 'IN' },
    { name: 'Indonesia', value: 'ID' },
    { name: 'Iran', value: 'IR' },
    { name: 'Iraq', value: 'IQ' },
    { name: 'Ireland', value: 'IE' },
    { name: 'Israel', value: 'IL' },
    { name: 'Italy', value: 'IT' },
    { name: 'Jamaica', value: 'JM' },
    { name: 'Japan', value: 'JP' },
    { name: 'Jordan', value: 'JO' },
    { name: 'Kazakhstan', value: 'KZ' },
    { name: 'Kenya', value: 'KE' },
    { name: 'Kiribati', value: 'KI' },
    { name: 'Kuwait', value: 'KW' },
    { name: 'Kyrgyzstan', value: 'KG' },
    { name: 'Laos', value: 'LA' },
    { name: 'Latvia', value: 'LV' },
    { name: 'Lebanon', value: 'LB' },
    { name: 'Lesotho', value: 'LS' },
    { name: 'Liberia', value: 'LR' },
    { name: 'Libya', value: 'LY' },
    { name: 'Liechtenstein', value: 'LI' },
    { name: 'Lithuania', value: 'LT' },
    { name: 'Luxembourg', value: 'LU' },
    { name: 'Madagascar', value: 'MG' },
    { name: 'Malawi', value: 'MW' },
    { name: 'Malaysia', value: 'MY' },
    { name: 'Maldives', value: 'MV' },
    { name: 'Mali', value: 'ML' },
    { name: 'Malta', value: 'MT' },
    { name: 'Marshall Islands', value: 'MH' },
    { name: 'Mauritania', value: 'MR' },
    { name: 'Mauritius', value: 'MU' },
    { name: 'Mexico', value: 'MX' },
    { name: 'Micronesia', value: 'FM' },
    { name: 'Moldova', value: 'MD' },
    { name: 'Monaco', value: 'MC' },
    { name: 'Mongolia', value: 'MN' },
    { name: 'Montenegro', value: 'ME' },
    { name: 'Morocco', value: 'MA' },
    { name: 'Mozambique', value: 'MZ' },
    { name: 'Myanmar', value: 'MM' },
    { name: 'Namibia', value: 'NA' },
    { name: 'Nauru', value: 'NR' },
    { name: 'Nepal', value: 'NP' },
    { name: 'Netherlands', value: 'NL' },
    { name: 'New Zealand', value: 'NZ' },
    { name: 'Nicaragua', value: 'NI' },
    { name: 'Niger', value: 'NE' },
    { name: 'Nigeria', value: 'NG' },
    { name: 'North Korea', value: 'KP' },
    { name: 'North Macedonia', value: 'MK' },
    { name: 'Norway', value: 'NO' },
    { name: 'Oman', value: 'OM' },
    { name: 'Pakistan', value: 'PK' },
    { name: 'Palau', value: 'PW' },
    { name: 'Panama', value: 'PA' },
    { name: 'Papua New Guinea', value: 'PG' },
    { name: 'Paraguay', value: 'PY' },
    { name: 'Peru', value: 'PE' },
    { name: 'Philippines', value: 'PH' },
    { name: 'Poland', value: 'PL' },
    { name: 'Portugal', value: 'PT' },
    { name: 'Qatar', value: 'QA' },
    { name: 'Romania', value: 'RO' },
    { name: 'Russia', value: 'RU' },
    { name: 'Rwanda', value: 'RW' },
    { name: 'Saint Kitts & Nevis', value: 'KN' },
    { name: 'Saint Lucia', value: 'LC' },
    { name: 'Samoa', value: 'WS' },
    { name: 'San Marino', value: 'SM' },
    { name: 'Sao Tome & Principe', value: 'ST' },
    { name: 'Saudi Arabia', value: 'SA' },
    { name: 'Senegal', value: 'SN' },
    { name: 'Serbia', value: 'RS' },
    { name: 'Seychelles', value: 'SC' },
    { name: 'Sierra Leone', value: 'SL' },
    { name: 'Singapore', value: 'SG' },
    { name: 'Slovakia', value: 'SK' },
    { name: 'Slovenia', value: 'SI' },
    { name: 'Solomon Islands', value: 'SB' },
    { name: 'Somalia', value: 'SO' },
    { name: 'South Africa', value: 'ZA' },
    { name: 'South Korea', value: 'KR' },
    { name: 'South Sudan', value: 'SS' },
    { name: 'Spain', value: 'ES' },
    { name: 'Sri Lanka', value: 'LK' },
    { name: 'St. Vincent & Grenadines', value: 'VC' },
    { name: 'State of Palestine', value: 'PS' },
    { name: 'Sudan', value: 'SD' },
    { name: 'Suriname', value: 'SR' },
    { name: 'Sweden', value: 'SE' },
    { name: 'Switzerland', value: 'CH' },
    { name: 'Syria', value: 'SY' },
    { name: 'Tajikistan', value: 'TJ' },
    { name: 'Tanzania', value: 'TZ' },
    { name: 'Thailand', value: 'TH' },
    { name: 'Timor-Leste', value: 'TL' },
    { name: 'Togo', value: 'TG' },
    { name: 'Tonga', value: 'TO' },
    { name: 'Trinidad and Tobago', value: 'TT' },
    { name: 'Tunisia', value: 'TN' },
    { name: 'Turkey', value: 'TR' },
    { name: 'Turkmenistan', value: 'TM' },
    { name: 'Tuvalu', value: 'TV' },
    { name: 'Uganda', value: 'UG' },
    { name: 'Ukraine', value: 'UA' },
    { name: 'United Arab Emirates', value: 'AE' },
    { name: 'United Kingdom', value: 'GB' },
    { name: 'United States', value: 'US' },
    { name: 'Uruguay', value: 'UY' },
    { name: 'Uzbekistan', value: 'UZ' },
    { name: 'Vanuatu', value: 'VU' },
    { name: 'Venezuela', value: 'VE' },
    { name: 'Vietnam', value: 'VN' },
    { name: 'Yemen', value: 'YE' },
    { name: 'Zambia', value: 'ZM' },
    { name: 'Zimbabwe', value: 'ZW' },
    { name: 'Others', value: 'Other' }
  ];
  static time_zone = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];
}
