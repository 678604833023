import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TicketCategory, TicketSubCategory } from '@penji/shared/data-access';
import { TicketCateStoreService } from '@penji/team/ticket/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-ticket-cate-item',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './ticket-cate-item.component.html',
  styleUrls: ['./ticket-cate-item.component.scss'],
})
export class TicketCateItemComponent {
  @Input() ticket_cate_id!: string;
  @Input() ticket_id!: string;
  @Input() clickable: boolean = false;
  @Input() font_size: string = '14px';
  ticketItem$!: Observable<TicketSubCategory | TicketCategory | undefined>;
  constructor(private _ticketStore$: TicketCateStoreService) { }
  ngOnChanges(): void {
    if (this.ticket_cate_id)
      this.ticketItem$ = this._ticketStore$.getOneTicketCate(this.ticket_cate_id);
  }
}
