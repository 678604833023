<div class="flex space-x-1">
  <ng-container *ngIf="project_data.assign_by && project_data.assign_by.length > 0">
    <div class="flex">
      <ng-container *ngFor="let user_id of project_data.assign_by">
        <div class="hover:z-10">
          <penji-user-item [user_id]="user_id" [user_avatar]="true" [user_size]="user_size"></penji-user-item>
        </div>
    </ng-container>
    </div>
    <ng-container *ngIf="reassign_on">
      <penji-project-reassign [project_data]="project_data"></penji-project-reassign>
    </ng-container>
</ng-container>
</div>
