import { inject, Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, combineLatest, distinctUntilChanged, EMPTY, map, Observable, of, switchMap, tap } from "rxjs";
import { AuthState, inititialState } from "./auth.state";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthService } from "../auth.service";
import { CustomerService, UserData } from "@penji/shared/data-access";

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
  customerSV = inject(CustomerService);
    constructor(
        private afAuth: AngularFireAuth,
        private authSV: AuthService,
    ) {
        super(inititialState);
    }
    readonly auth_id$ = this.select(state => state.auth_profile?.uid);
    readonly authProfile$ = this.select(state => state.auth_profile);
    readonly refresh_token$ = this.select(state => state.refresh_token)

    readonly init = this.effect((trigger$) => {
        return trigger$.pipe(
            switchMap(() => {
                return this.afAuth.authState.pipe(
                    tap(async (auth) => {
                        // console.log('init authStore', auth)
                        if(auth){
                            const rs = await this.authSV.getUserDetail(auth?.uid);
                            this.setState((state) => {
                                return {
                                    ...state,
                                    auth_profile: rs,
                                    refresh_token: auth.refreshToken
                                }
                            })

                        }
                        return EMPTY
                    }
                ),
                catchError(() => EMPTY)
                )
            })
        )
    })
    readonly updateAuthProfile = this.updater((state: AuthState, user: UserData) => {
        return {
            ...state,
            auth_profile: user
        }
    });
    updateAuthProfile$ = this.effect((user$: Observable<UserData>) => {
        return user$.pipe(
            distinctUntilChanged(),
            switchMap(
                (user) => {
                    return combineLatest(this.customerSV.updateCustomer(user), of(user))
                }
            ),
            map(([result, user]) => {
                if(result.flag){
                    this.updateAuthProfile(user)
                }
            })
        )
    })
}
