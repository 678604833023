import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DocumentData } from 'firebase/firestore';
import { map, of } from 'rxjs';
import { Project } from '../models/project.model';

@Injectable({
    providedIn: 'root'
})
export class WProjectTodoService {
    constructor(private afs: AngularFirestore) { }
    getListProjectActive(user_id: string, designer_team_id: string) {
        if (!user_id && designer_team_id) {
            return this.afs.collection<DocumentData>('project_v2', ref => {
                return ref
                    .where("pause", "==", false)
                    .where('status', '<=', 2)
                    // .where("assigned", "==", true)
                    .where("in_queue", "==", false)
                    .where("pending_review", "==", false)
                    .where("status_addition", "in", [0, 1, 2])
                    .where("assign_team_designer", "==", designer_team_id)
            }).snapshotChanges().pipe(map(action => action.map(res => {
                const data = res.payload.doc.data() as Project;
                data.id = res.payload.doc.id;
                data.doc = res.payload.doc;
                data.action = res.type;
                return data;
            })));
        } else if (user_id && !designer_team_id) {
            return this.afs.collection<DocumentData>('project_v2', ref => {
                return ref
                    .where("pause", "==", false)
                    .where('status', '<=', 2)
                    .where("assigned", "==", true)
                    .where("in_queue", "==", false)
                    .where("pending_review", "==", false)
                    .where("status_addition", "in", [0, 1, 2])
                    .where("assign_by", "array-contains", user_id);
            }).snapshotChanges().pipe(map(action => action.map(res => {
                const data = res.payload.doc.data() as Project;
                data.id = res.payload.doc.id;
                data.doc = res.payload.doc;
                data.action = res.type;
                return data;
            })));
        } else if (user_id && designer_team_id) {
            return this.afs.collection<DocumentData>('project_v2', ref => {
                return ref
                    .where("pause", "==", false)
                    .where('status', '<=', 2)
                    .where("assigned", "==", true)
                    .where("in_queue", "==", false)
                    .where("pending_review", "==", false)
                    .where("status_addition", "in", [0, 1, 2])
                    .where("assign_team_designer", "==", designer_team_id)
                    .where("assign_by", "array-contains", user_id);
            }).snapshotChanges().pipe(map(action => action.map(res => {
                const data = res.payload.doc.data() as Project;
                data.id = res.payload.doc.id;
                data.doc = res.payload.doc;
                data.action = res.type;
                return data;
            })));
        } else {
            return of([new Project]);
        }
    }
}
