import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { TicketCateService, TicketCategory, TicketSubCategory } from '@penji/shared/data-access';
import { EMPTY, catchError, switchMap } from 'rxjs';

interface TicketCategoryState extends EntityState<TicketCategory | TicketSubCategory> { }
const adapter = createEntityAdapter<TicketCategory | TicketSubCategory>();
const initialState: TicketCategoryState = adapter.getInitialState({})
const { selectAll } = adapter.getSelectors();
@Injectable({ providedIn: 'root' })
export class TicketCateStoreService extends ComponentStore<TicketCategoryState>{
    constructor(
        private ticketCateSV: TicketCateService
    ) { super(initialState); }
    readonly data$ = this.select(selectAll);

    init() {
        this.loadTicketCate$();
    }

    getOneTicketCate(ticket_cate_id: string) {
        return this.select(s => s.entities[ticket_cate_id])
    }
    getListMainTicketCate() {
        return this.select(
            this.data$,
            (rs) => rs.filter(cate => cate.main)
        )
    }
    getListSubTicketCate() {
        return this.select(
            this.data$,
            (rs) => rs.filter(cate => !cate.main)
        )
    }

    loadTicketCate$ = this.effect((params$) => {
        return params$.pipe(
            switchMap(async () => {
                return this.ticketCateSV.getAllCategoryClass().then(
                    (cate) => {
                        if (cate)
                            this.setState((state) => adapter.setAll(cate, state))
                    },
                    catchError(() => EMPTY),
                );
            })
        )
    })
}
