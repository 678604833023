
import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, of } from 'rxjs';
import { Team } from '../models/team.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends LogService<Team> {
  nzMessageService = inject(NzMessageService);
  getTeamDetail(team_id: string) {
    return this.afs.collection('team').doc<Team>(team_id).valueChanges();
  }

  getTeamMember(team_id: string) {
    return this.afs.collection('team').doc(team_id).collection('team_member').valueChanges({ idField: 'userId' });
  }

  updateTeam(team_id: string, customer_id: string, data: any, is_update: boolean) {
    const batch = this.afs.firestore.batch();
    batch.update(this.afs.firestore.collection('team').doc(team_id), { ...data }, { merge: true });
    batch.update(this.afs.firestore.collection('payment').doc(customer_id), { 'not_update_plan': is_update });
    return batch.commit().then(() => {
      this.log_model.action = 'update';
      const obj = { ...data };
      obj['not_update_plan'] = is_update;
      obj['team_id'] = team_id;
      this.log_model.data = { ...new Team, ...obj };
      this.createLog();
      return { flag: true, message: 'Success' }
    }).catch(error => ({ flag: false, message: error.message }))
  }
  changeDesignerTeamForCustomer(client_team_id: string, team_designer_id_new: string) {
    return this.afs.firestore.doc(`team/${client_team_id}`).update({
      team_designer_id : team_designer_id_new,
      clients_like: [],
      clients_hate: []
    }).then(()=>{
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err=>{
      return {
        flag: false,
        message: err
      }
    })
  }
  updateDesignersLikeForCustomer(client_team_id:string, list: string[]){
    return this.afs.firestore.doc(`team/${client_team_id}`).update({
      clients_like: list
    }).then(()=>{
      this.nzMessageService.success('update succesfully!');
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err=>{
      return {
        flag: false,
        message: err
      }
    })
  }
  updateDesignersHateForCustomer(client_team_id:string, list: string[]){
    return this.afs.firestore.doc(`team/${client_team_id}`).update({
      clients_hate: list
    }).then(()=>{
      this.nzMessageService.success('update succesfully!');
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err=>{
      return {
        flag: false,
        message: err
      }
    })
  }

  async listTeam(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let query: Query = this.afs.firestore.collection('team');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator , q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        query = query.startAfter(start_after);
        if(limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if(limit != -1) query = query.limitToLast(limit);
      } else {
        if(limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Team> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Team;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listTeamRealTime(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let query: Query = this.afs.firestore.collection('team');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator , q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        query = query.startAfter(start_after);
        if(limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if(limit != -1) query = query.limitToLast(limit);
      } else {
        if(limit != -1) query = query.limit(limit);
      }

      return new Observable<Team[]>(observ => {
        query.onSnapshot(querySnapshot => {
          const list: Array<Team> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as Team;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }
}
