<form nzLayout="vertical" nz-form [formGroup]="validateForm" class="p-6" (submit)="onSubmit()">
    <div nz-row>
        <div nz-col nzSpan="24">
            <h4 class="mb-6">{{form_type==='add' ? 'Add' : 'Edit'}} a request off</h4>
        </div>
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label class="w-full">Date off</nz-form-label>
            <nz-date-picker formControlName="off_at_tmp" nzFormat="yyyy-MM-dd" nzPlaceHolder="Date off entry"
                [nzDisabledDate]="disabledDayOff">
            </nz-date-picker>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label class="w-full">Member</nz-form-label>
            <div class="flex items-center space-x-1">
                <ng-container *ngIf="validateForm.get('uid')?.value">
                    <div class="hover:z-10">
                        <penji-user-item [user_id]="validateForm.get('uid')?.value"
                            [user_avatar]="true"></penji-user-item>
                    </div>
                </ng-container>
                <span (click)="openReassignMember()" id="reassign-btn">+</span>
            </div>
        </nz-form-item>
        <ng-container *ngIf="memberDetail$ | async as memberDetail">
            <nz-form-item nz-col nzSpan="24">
                <nz-form-label class="w-full">PTO / UPTO</nz-form-label>
                <nz-select formControlName="pto" nzPlaceHolder="Select PTO"
                    [nzDisabled]="(memberDetail.pto_earn - (memberDetail.pto_used || 0)) > 0 ? false:true">
                    <nz-option nzLabel="PTO" [nzValue]="1"></nz-option>
                    <nz-option nzLabel="UPTO" [nzValue]="2"></nz-option>
                </nz-select>
                <p>(Available PTO: {{memberDetail.pto_earn - (memberDetail.pto_used || 0)}})</p>
            </nz-form-item>
        </ng-container>
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label class="w-full">Reason</nz-form-label>
            <textarea nz-input formControlName="reason" [nzAutosize]="{ minRows: 4, maxRows: 6 }"></textarea>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="24" class="text-right">
            <button class="px-6" type="submit" nz-button nzSize="large" nzType="primary"
                [disabled]="validateForm.invalid || loading">
                {{form_type==='add' ? 'Add' : 'Save changes'}}
            </button>
        </nz-form-item>

    </div>
</form>
<!-- <pre>{{validateForm.value | json}}</pre> -->