/* eslint-disable @typescript-eslint/no-empty-interface */
import { Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';

export class Media {
  id!: string;
  avatar?: string;
  created_at?: Timestamp;
  extension?: string;
  fullname?: string;
  medium?: string;
  name?: string;
  original?: string;
  thumb?: string;
  type?: string;
  one_off_design?: boolean;
  public?: boolean;
  size?: number;
  owner_id!: string;
  log_type = "media";
}

export interface MediaState extends EntityState<Media> { }
