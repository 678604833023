import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@penji/shared/environments';
import { map, share } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceWordpressService {
  private wp_url = environment.marketplace_back_end_url;
  constructor(
    private http: HttpClient,
  ) { }

  /* type: users, media, pages, posts, categories, project, designer, design_category */
  getSingleById(type: string, id: string) {
    const url =  `${this.wp_url}wp-json/wp/v2/${type}/${id}`;
    const a = this.http.get(url).pipe(share())
    return a;
  }

  /* type: users, media, pages, posts, categories, project, designer, design_category */
  getSingleBySlug(type: string, slug: string) {
    const url =  `${this.wp_url}wp-json/wp/v2/${type}?slug=${slug}`;
    return this.http.get(url).pipe(map((rs: any) => rs[0] ));
  }

  /*
  type: users, media, pages, posts, categories, project, designer, design_category
  taxonomy_type: categories, design_category, desinger_id
  */
  listAll(type: string, page?: number, per_page?: number, query_object?: Array<{taxonomy_type: string, taxonomy_value: any[]}>) {
    let url =  `${this.wp_url}wp-json/wp/v2/${type}?`
    if (page) url += `&page=${page}`;
    if (per_page) url += `&per_page=${per_page}`;
    if (query_object && query_object.length > 0) {
      query_object.forEach(rs => {
        var temp = rs.taxonomy_value.join(",");
        url += `&${rs.taxonomy_type}=${temp}`;
      });
    }
    // console.log(url);
    return this.http.get(url).pipe(map((rs: any) => [...rs]));
  }
}
