import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { Payout } from '../models/payout.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';

@Injectable({
    providedIn: 'root'
})
export class PayoutService extends LogService<Payout>{

    async listPayout(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string) {
        try {
            let query: Query = this.afs.firestore.collection('payout');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }

            query = query.orderBy('created_at', 'desc');

            if (start_after) {
                const doc = await this.afs.firestore.doc(`payout/${start_after}`).get();
                query = query.startAfter(doc).limit(limit);
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`payout/${end_before}`).get();
                query = query.endBefore(doc).limitToLast(limit);
            } else {
                query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list: Array<Payout> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as Payout;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    getPayoutDetail(payout_id: string) {
        return this.afs.doc<Payout>(`payout/${payout_id}`).valueChanges({ idField: 'id' });
    }

    addPayout(payout: Payout) {
        return this.afs.collection(`payout`).add({...payout}).then(rs => {
            this.log_model.action = 'create';
            this.log_model.data = { ... new Payout, ...payout };
            this.createLog();
            return ({ flag: true, message: 'Payout successfully added!', data: {...payout, id: rs.id} });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message, data: {} as Payout });
        });
    }

    updatePayout(payout: Payout) {
        return this.afs.doc(`payout/${payout.id}`).update({...payout}).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ... new Payout, ...payout };
            this.createLog();
            return ({ flag: true, message: 'Payout successfully updated!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    deletePayout(payout_id: string) {
        return this.afs.doc(`payout/${payout_id}`).delete().then(() => {
            this.log_model.action = 'delete';
            this.log_model.data = { ... new Payout, payout_id: payout_id } as Payout;
            this.createLog();
            return ({ flag: true, message: 'Payout successfully deleted!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

}
