import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MarketplaceWordpressService } from '@penji/shared/data-access';
import { catchError, EMPTY, map, mergeMap, Observable, switchMap, take } from 'rxjs';

interface MediaState extends EntityState<any> {
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: MediaState = adapter.getInitialState({
  error: ''
})

@Injectable({
  providedIn: 'root'
})
export class MediaStoreService extends ComponentStore<MediaState> {

  marketPlaceSV = inject(MarketplaceWordpressService);

  constructor() {
    super(initialState);
  }

  getOneMediaById(media_id: string) {
    return this.select((s) => s.entities[media_id]);
  }

  loadMedia$ = this.effect((media_id$: Observable<string>)=> {
    return media_id$.pipe(
      mergeMap((media_id) => {
        return this.select((s) => s.entities[media_id]).pipe(
          take(1),
          switchMap(rs => {
            if(!rs)
            {
              return this.marketPlaceSV.getSingleById('media', media_id).pipe(
                map(rs => {
                  if (rs) {
                    this.setState((state) => adapter.setOne(rs, state));
                  }
                }),
                catchError(rs => {
                  this.patchState({error: rs.error.code});
                  return EMPTY;
                })
              )
            }
            return EMPTY;
          })
        )
      })
    )
  })


}
