import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { DocumentReference, Timestamp } from '@firebase/firestore-types';

export class QuestionCategory {
  id!: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  created_at!: Timestamp;
  updated_at!: Timestamp;
  title!: string;
  order!: number;
  log_type = 'question-category';
}

export class QuestionAnswer {
  id!: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  created_at!: Timestamp;
  updated_at!: Timestamp;
  answer!: Array<{[key: string]: any}>;
  question!: string;
  question_category_id!: string;
  used!: number;
  log_type = 'question-answer';
}
