import { DocumentData, DocumentReference, QueryDocumentSnapshot } from "@angular/fire/compat/firestore";
import { Timestamp } from "firebase/firestore";

export class Team {
  id?: string;
  doc?: QueryDocumentSnapshot<DocumentData> | string;
  created_at?: Timestamp;
  mail_first_project?: boolean;
  number_of_active_project?: number;
  number_of_member?: number;
  number_of_new_project?: number | undefined;
  number_of_queue?: number;
  owner_id?: string;
  owner_ref?: DocumentReference | string;
  pause?: boolean;
  payment?: boolean;
  total_dislike?: number;
  total_like?: number;
  total_project_complete?: number;
  team_designer_id?: string;
  clients_like?: string[];
  clients_hate?: string[];
  log_type = 'team';
}
export class TeamMember {
  userId?: string;
  role?: string;
  log_type?:'team_member';
}
