/* eslint-disable */
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { DashboardService, Widget } from '@penji/shared/data-access';
import { EMPTY, Observable, concatMap, map, mergeMap, switchMap, tap } from 'rxjs';
interface WidgetState extends EntityState<Widget> {
    isLoading: boolean;
}
const adapter = createEntityAdapter<Widget>();
const initialState: WidgetState = adapter.getInitialState({ isLoading: false });
const { selectAll } = adapter.getSelectors();
@Injectable()
export class DahboardStoreService extends ComponentStore<WidgetState> {
    constructor(private dashboardSV: DashboardService) {
        super(initialState)
    }
    readonly data$ = this.select(selectAll);
    readonly isLoading$ = this.select(s => s.isLoading);

    loadWidget$ = this.effect((source$: Observable<any>) => {
        return source$.pipe(
            tap(() => this.patchState({ isLoading: true })),
            switchMap(() => {
                return this.dashboardSV.getDashboard();
            }),
            map(dashboard => {
                if (dashboard?.widgets) {
                    this.setState(state => adapter.setAll(dashboard.widgets, state));
                    this.patchState({ isLoading: false });
                }
                return dashboard;
            })
        );
    })
    addWidget$ = this.effect((widget$: Observable<Widget>) => {
        return widget$.pipe(
            concatMap((widget) => {
                if (widget)
                    this.setState((state) => adapter.upsertOne(widget, state))
                return EMPTY;
            })
        )
    })
    updateWidget$ = this.effect((widget$: Observable<Widget>) => {
        return widget$.pipe(
            concatMap((widget) => {
                if (widget)
                    this.setState((state) => {
                        return adapter.updateOne({
                            id: widget.componentName ?? '',
                            changes: widget
                        }, state)
                    })
                return EMPTY
            })
        )
    })
    deleteWidget$ = this.effect((component_name$: Observable<string | undefined>) => {
        return component_name$.pipe(
            mergeMap((component_name) => {
                if (component_name)
                    this.setState((state) => adapter.removeOne(component_name, state))
                return EMPTY
            })
        )
    })
}
