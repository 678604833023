import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { MarketplaceWordpressService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, concatMap, map, of, switchMap, take, tap } from 'rxjs';


interface ProjectState extends EntityState<any> {
  loading: boolean,
  end_of_list: boolean,
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: ProjectState = adapter.getInitialState({
  loading: false,
  end_of_list: false,
  error: ''
})
const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class ProjectStoreService extends ComponentStore<ProjectState>{

  limit = 12;

  constructor(private marketPlaceSv: MarketplaceWordpressService) {
    super(initialState);
  }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  end_of_list$ = this.select((s) => s.end_of_list);
  error$ = this.select((s) => s.error);

  loadProject$ = this.effect((params$: Observable<{}>) => {
    return params$.pipe(
      tap((params: any) => {
        if (params['page'] == 1) {
          this.setState((state) => adapter.removeAll(state));
          this.patchState({end_of_list: false});
        }
        this.patchState({ loading: true, error: '' })
      }),
      concatMap((params: any) => {
        let query_object = [];
        if (params['design_category_id']) {
          query_object.push({
            taxonomy_type: 'design_category',
            taxonomy_value: [params['design_category_id']]
          })
        }
        if (params['certified']) {
          query_object.push({
            taxonomy_type: 'blue_tick',
            taxonomy_value: [params['certified']]
          })
        }
        query_object.push({
          taxonomy_type: 'orderby',
          taxonomy_value: ['slug']
        })
        return this.marketPlaceSv.listAll('project', params['page'], this.limit, query_object).pipe(
          take(1),
          concatMap((project_list: any[]) => {
            if (project_list && project_list.length > 0 ) {
              const promises: any = [];
              project_list.forEach(t => {
                promises.push(this.marketPlaceSv.getSingleById('designer', t.acf.designer_id).pipe(take(1)).toPromise())
              });
              return combineLatest(Promise.all(promises), of(project_list)).pipe(
                map(([designer_list, project_list]) => {
                  if (project_list && project_list.length > 0) {
                    project_list.forEach((t, index) => project_list[index]={
                      ...t,
                      designer: designer_list[index]
                    });
                    return project_list;
                  } else {
                    return [];
                  }
                })
              );
            } else {
              return of([])
            }
          }),
          catchError(rs => {
            if (rs.error.code == "rest_post_invalid_page_number")
              this.patchState({ loading: false, end_of_list: true });
            else {
              this.patchState({ error: rs.error.code });
            }
            return of([]);
          })
        );
      }),
      map(project_final_list => {
        if (project_final_list && project_final_list.length > 0) {
          project_final_list.forEach(t => {
            this.setState((state) => adapter.setOne(t, state));
          });
          this.patchState({ loading: false })
        }

        if (project_final_list.length == 0 || project_final_list.length < this.limit) {
          this.patchState({ loading: false, end_of_list: true });
        }
      })
    )
  })
}
