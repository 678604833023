import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTagModule } from 'ng-zorro-antd/tag';

@Component({
  selector: 'penji-request-off-approved',
  standalone: true,
  imports: [CommonModule, NzTagModule],
  templateUrl: './request-off-approved.component.html',
  styleUrls: ['./request-off-approved.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestOffApprovedComponent {
  @Input() approved: number = 0;
}
