import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, Query } from "@angular/fire/compat/firestore";
import * as firebase from 'firebase/firestore';
import { of, switchMap, take, throttleTime } from "rxjs";
import { Media } from "../models/media.model";
import { LogService } from "./log.service";
import { WhereQueryInterface } from "../interfaces/where-query-interface";

@Injectable({
  providedIn: 'root'
})
export class MediaService extends LogService<Media> {

  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  override afs = inject(AngularFirestore);
  async getMediaRefDetail(user_id: string, media_id: string) {
    return await this.afs.firestore.collection('media').doc(user_id).collection('files').doc(media_id).get().then((result: any) => {
      const media = result.data() as Media;
      media.id = result.id;
      return media;
    }).catch(error => {
      return { flag: false, message: error }
    });
  }
  //upload multi part start upload
  startUploadForMultiPart(file_name: string, file_size: number) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'token': token,
            'file_name': file_name,
            'file_size': file_size
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post('https://penjiapi.herokuapp.com/api/storage/start-upload', data, httpOptions)
              .pipe(throttleTime(500))
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  getPresignedUrlForMultiPartUpload(file_name: string, part_number: number, upload_id: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'file_name': file_name,
            'part_number': part_number,
            'upload_id': upload_id,
            'token': token
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post('https://penjiapi.herokuapp.com/api/storage/get-upload-part-url', data, httpOptions)
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  getCompleteMultiPartUpload(file_name: string, parts: any, upload_id: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'file_name': file_name,
            'parts': parts,
            'upload_id': upload_id,
            'token': token
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post('https://penjiapi.herokuapp.com/api/storage/complete-upload', data, httpOptions)
              .pipe(throttleTime(500))
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  async addOneMedia(user_id: string, file: any, type: string, public_show: boolean = false, size: number = 0) {
    const data = new Media();
    data.size = size;
    if (type === 'image') {
      const ext = file.original.name.substr(file.original.name.lastIndexOf('.') + 1);
      const name = file.original.name.substr(0, file.original.name.lastIndexOf('_'));
      const newName = name + '.' + ext;
      data.created_at = firebase.Timestamp.now();
      data.type = 'image';
      data.fullname = file.original.name;
      data.name = newName;
      data.original = file.original.url;
      data.medium = file.medium.url;
      data.thumb = file.thumb.url;
      data.avatar = file.avatar.url;
      data.public = public_show;
    } else {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const name = file.name.substr(0, file.name.lastIndexOf('_'));
      const newName = name + '.' + ext;
      data.created_at = firebase.Timestamp.now();
      data.type = type;
      data.fullname = file.name;
      data.name = newName
      data.original = file.url;
      data.extension = ext;
      data.public = public_show;
    }

    const kq = await this.afs.firestore.collection(`media/${user_id}/files/`).add({ ...data }).then(rs => rs).catch(err => err);
    if (kq.message) {
      return null;
    } else {
      this.log_model.action = 'upload';
      this.log_model.data = { ...new Media, ...data };
      this.createLog();
      return kq;
    }
  }
  async deleteMediaRef(user_id: string, media_id: string) {
    return await this.afs.firestore.collection('media').doc(user_id).collection('files').doc(media_id).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ...new Media, id: media_id } as Media;
      this.createLog();
      return { flag: true, massage: 'Media successfully deleted!' }
    }).catch(error => {
      return { flag: false, message: error }
    });
  }
  async getListMedias(
    user_id: string,
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc: boolean = true) {
    try {
      let query: Query = this.afs.firestore.collection('media').doc(user_id).collection('files');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`media/${user_id}/files/${start_after}`).get();
        query = query.startAfter(doc)
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`media/${user_id}/files/${end_before}`).get();
        query = query.endBefore(doc)
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list = Array<Media>();
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Media;
          data.id = doc.id;
          data.owner_id = user_id;
          list.push(data);
        });
        return list;
      }).catch(err => {
        console.log(err);
        return [];
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  updateMedia(media: Media) {
    return this.afs.doc(`media/${media.owner_id}/files/${media.id}`).update({...media}).then(() => {
      return ({flag: true, message: 'Media succesfully updated!'});
    }).catch(err => {
      return ({flag: false, message: err.message});
    })
  }
}
