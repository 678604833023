import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Ticket, TicketService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, concatMap, map, mergeMap, of, switchMap, tap } from 'rxjs';
import * as firebase from 'firebase/firestore';

export interface TicketState extends EntityState<Ticket> {
  loading: boolean;
  success: boolean;
  disable_previous: boolean,
  disable_next: boolean,
}
export const adapter = createEntityAdapter<Ticket>();
export const initialState: TicketState = adapter.getInitialState({
  loading: false,
  success: false,
  disable_previous: false,
  disable_next: false,
})
const { selectAll } = adapter.getSelectors();
@Injectable()
export class TicketStoreService extends ComponentStore<TicketState>{

  limit = 20;

  constructor(
    private ticketSV: TicketService
  ) {
    super(initialState);
  }
  readonly data$ = this.select(selectAll);
  readonly loading$ = this.select(s => s.loading);
  disable_previous$ = this.select((s) => s.disable_previous);
  disable_next$ = this.select((s) => s.disable_next);

  loadTicket$ = this.effect((params$: Observable<{}>) => {
    return params$.pipe(
      tap(() => {
        this.setState((state) => adapter.removeAll(state));
        this.patchState({loading: true, disable_previous: false, disable_next: false})
      }),
      switchMap((params: any) => {
        let where_query: Array<WhereQueryInterface> | undefined;
        let start_after;
        let end_before;
        if (params) {
          for (let k in params) {
            if (k == 'start_after') {
              start_after = params[k];
            } else if (k == 'end_before') {
              end_before = params[k];
            } else if (k == 'start_at') {
              if (where_query) {
                where_query.push({
                  field_name: 'created_at',
                  field_operator: '>=',
                  field_value: firebase.Timestamp.fromDate(new Date(new Date(params[k]).setHours(0, 0, 0)))
                });
              } else {
                where_query = [{
                  field_name: 'created_at',
                  field_operator: '>=',
                  field_value: firebase.Timestamp.fromDate(new Date(new Date(params[k]).setHours(0, 0, 0)))
                }];
              }
            } else if (k == 'end_at') {
              if (where_query) {
                where_query.push({
                  field_name: 'created_at',
                  field_operator: '<=',
                  field_value: firebase.Timestamp.fromDate(new Date(new Date(params[k]).setHours(23, 59, 59)))
                });
              } else {
                where_query = [{
                  field_name: 'created_at',
                  field_operator: '<=',
                  field_value: firebase.Timestamp.fromDate(new Date(new Date(params[k]).setHours(23, 59, 59)))
                }];
              }
            } else if (k == 'ticket_source' || k == 'status') {
              if (where_query) {
                where_query.push({
                  field_name: k, field_operator: '==', field_value: parseInt(params[k])
                });
              } else {
                where_query = [{
                  field_name: k, field_operator: '==', field_value: parseInt(params[k])
                }];
              }
            } else if (k == 'user_array') {
              if (where_query) {
                where_query.push({
                  field_name: k, field_operator: 'array-contains', field_value: params[k]
                });
              } else {
                where_query = [{
                  field_name: k, field_operator: 'array-contains', field_value: params[k]
                }];
              }
            } else {
              if (where_query) {
                where_query.push({
                  field_name: k, field_operator: '==', field_value: params[k]
                });
              } else {
                where_query = [{
                  field_name: k, field_operator: '==', field_value: params[k]
                }];
              }
            }
          }
        }
        return this.ticketSV.listTicket(this.limit, where_query, start_after, end_before).then(rs => {
          if (rs && rs.length > 0) {
            const promise = [
              this.ticketSV.listTicket(1, where_query, undefined, rs[0].id),
              this.ticketSV.listTicket(1, where_query, rs[rs.length - 1].id, undefined)
            ]
            return combineLatest(of(rs), Promise.all(promise)).pipe(
              map(([rs, before_after]) => {
                if (before_after[0] && before_after[0].length > 0) {
                  this.patchState({disable_previous: false});
                } else {
                  this.patchState({disable_previous: true});
                }
                if (before_after[1] && before_after[1].length > 0) {
                  this.patchState({disable_next: false});
                } else {
                  this.patchState({disable_next: true});
                }
                return rs;
              })
            )
          } else {
            return of([] as Ticket[]);
          }
        }).catch(err => {
          console.log(err);
          return of([] as Ticket[]);
        })
      }),
      switchMap(rs => rs),
      map(final_list => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }
        if (final_list.length == 0 || final_list.length < this.limit) {
          this.patchState({ loading: false });
          if (final_list.length == 0) {
            this.patchState({disable_previous: true, disable_next: true});
          }
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false });
        return EMPTY;
      })
    )
  })

  updateTicket$ = this.effect((ticket$: Observable<Ticket>) => {
    return ticket$.pipe(
      tap(() => this.patchState({
        loading: true
      })),
      concatMap((updateTicket) =>
        this.ticketSV.updateTicket(updateTicket).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) => {
                return adapter.updateOne({
                  id: updateTicket.id,
                  changes: updateTicket
                }, state)
              })
              this.patchState({ loading: false, success: true });
            } else {
              this.patchState({ loading: false });
            }
            return EMPTY;
          }, catchError(() => {
            this.patchState({ loading: false });
            return EMPTY;
          })
        ))
    )
  })

  deleteTicket$ = this.effect((ticket_id$: Observable<string>) => {
    return ticket_id$.pipe(
      tap(() => this.patchState({
        loading: true
      })),
      mergeMap((ticket_id) => this.ticketSV.deleteTicket(ticket_id).then(
        (rs) => {
          if (rs.flag) {
            this.setState((state) => adapter.removeOne(ticket_id, state));
            this.patchState({ loading: false, success: true });
          } else {
            this.patchState({ loading: false });
          }
          return EMPTY;
        }, catchError(() => {
          this.patchState({ loading: false });
          return EMPTY;
        })
      ))
    )
  })

  addTicket$ = this.effect((ticket$: Observable<Ticket>) => {
    return ticket$.pipe(
      concatMap((ticket) => this.ticketSV.addTicket(ticket).then(
        (rs) => {
          if (rs.flag && rs.data) {
            this.setState((state) => adapter.addOne(rs.data, state))
            this.patchState({ loading: false, success: true });
          } else {
            this.patchState({ loading: false });
          }
          return EMPTY;
        }, catchError(() => {
          this.patchState({ loading: false });
          return EMPTY;
        })
      ))
    )
  })
}
