/* eslint-disable */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CategoryTitleComponent } from '@penji/team/categories/category-ui/category-title';
import { WProjectTodoStoreService } from '@penji/team/dashboard/data-access';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import { ProjectAssignedComponent } from '@penji/team/project/project-ui/project-assigned';
import { ProjectStatusComponent } from '@penji/team/project/project-ui/project-status';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';

@Component({
  selector: 'penji-w-project-todo',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzDividerModule,
    ProjectStatusComponent,
    ProjectAssignedComponent,
    DesignerTeamNameComponent,
    CategoryTitleComponent
  ],
  templateUrl: './w-project-todo.component.html',
  styleUrls: ['./w-project-todo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WProjectTodoComponent implements OnInit {
  @Input() user_id: string | undefined;
  @Input() designer_team_id: string | undefined;
  data$ = this.store.data$;
  isLoading$ = this.store.isLoading$;
  constructor(private store: WProjectTodoStoreService) { }
  ngOnInit(): void {
    if (this.user_id || this.designer_team_id) {
      this.store.loadProjects$({ user_id: this.user_id, designer_team_id: this.designer_team_id });
    }

  }
}
