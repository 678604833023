<!-- prettier-ignore -->
<ul
  nz-menu
  [nzMode]="menuMode"
  [nzTheme]="menuTheme"
  class="border-none bg-transparent {{ (className && className) || '' }}"
>
  <li
    nz-menu-item
    nzSelected
    class="text-white text-[17px] font-lab-bold after:!content-[]"
  >
    <a style="color: white" class="hover:opacity-80" target="_blank" href="https://penji.co/why-penji">Why Penji?</a>
  </li>
  <li
    nz-menu-item
    class="text-white text-[17px] font-lab-bold after:!content-[]"
  >
    <a style="color: white" class="hover:opacity-80" target="_blank" href="https://penji.co/how-it-works">How it works?</a>
  </li>
  <li
    nz-menu-item
    class="text-white text-[17px] font-lab-bold after:!content-[]"
  >
    <a style="color: white" class="hover:opacity-80" target="_blank" href="https://penji.co/pricing">Pricing</a>
  </li>
  <li
    nz-menu-item
    class="text-white text-[17px] font-lab-bold after:!content-[]"
  >
    <a style="color: white" class="hover:opacity-80" target="_blank" href="https://penji.co/our-work">Our work</a>
  </li>
</ul>
<ng-template #moreItemMenu>
  <div class="flex items-center">
    More&nbsp;
    <span class="w-3" nz-icon nzType="caret-down" nzTheme="outline"></span>
  </div>
</ng-template>
