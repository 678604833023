import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { MarketplaceWordpressService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, concatMap, distinctUntilChanged, map, mergeMap, of, switchMap, take, tap } from 'rxjs';

interface DesignerState extends EntityState<any> {
  loading: boolean,
  end_of_list: boolean,
  designer_detail: object | null,
  designer_porfolio: Array<object>,
  loading_designer_porfolio: boolean,
  end_of_list_designer_porfolio: boolean
}
const adapter = createEntityAdapter<any>();
const initialState: DesignerState = adapter.getInitialState({
  loading: false,
  end_of_list: false,
  designer_detail: null,
  designer_porfolio: [],
  loading_designer_porfolio: false,
  end_of_list_designer_porfolio: false,
})
const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class DesignerStoreService extends ComponentStore<DesignerState>{
    limit = 10;
    limit_porfolio = 6;

    constructor(private marketPlaceSv: MarketplaceWordpressService) {
      super(initialState);
    }

    data$ = this.select(selectAll);
    loading$ = this.select((s) => s.loading);
    end_of_list$ = this.select((s) => s.end_of_list);
    designer_detail$ = this.select((s) => s.designer_detail);
    designer_porfolio$ = this.select((s) => s.designer_porfolio);
    loading_designer_porfolio$ = this.select((s) => s.loading_designer_porfolio);
    end_of_list_designer_porfolio$ = this.select((s) => s.end_of_list_designer_porfolio);

    getDesignerDetail$ = this.effect((designer_id$: Observable<string | null>) => {
      return designer_id$.pipe(
        distinctUntilChanged(),
        tap( () => this.patchState({ designer_detail: null }) ),
        switchMap((designer_id: string | null) => {
          if (designer_id) {
            return this.select((s) => s.entities[designer_id]).pipe(rs => {
              return rs.pipe(
                switchMap(rs => {
                  if (rs) {
                    return of(rs)
                  }
                  return this.marketPlaceSv.getSingleById('designer', designer_id);
                }),
                map(
                  (rs) => {
                    this.patchState({ designer_detail: rs });
                  }
                ),
                catchError(rs => {
                  this.patchState({ designer_detail: {} });
                  return EMPTY;
                })
              )
            })
          } else {
            this.patchState({ designer_detail: {} })
            return EMPTY;
          }
        })
      )
    });

    getDesignerPortfolio$ = this.effect((params$: Observable<{}>) => {
      return params$.pipe(
        tap((params: any) => {
          if (params['page'] == 1)
            this.patchState({ designer_porfolio: [], end_of_list_designer_porfolio: false });
          this.patchState({loading_designer_porfolio: true});
        }),
        switchMap((params: any) => {
          if (params['designer_id']) {
            let query_object = [];
            query_object.push({
              taxonomy_type: 'designer_id',
              taxonomy_value: [params['designer_id']]
            });
            if (params['design_category_id']) {
              query_object.push({
                taxonomy_type: 'design_category',
                taxonomy_value: [params['design_category_id']]
              })
            }
            return this.marketPlaceSv.listAll('project', params['page'], this.limit_porfolio, query_object).pipe(
              catchError(rs => {
                if (rs.error.code == "rest_post_invalid_page_number") {
                  this.patchState({ loading_designer_porfolio: false, end_of_list_designer_porfolio: true });
                }
                return of([]);
              })
            );
          } else {
            return EMPTY;
          }
        }),
        map(list => {
          if (list && list.length > 0) {
            this.patchState(s => {
              s.designer_porfolio = [...s.designer_porfolio, ...list];
              s.loading_designer_porfolio = false;
              return s;
            });
          }

          if (list.length == 0 || list.length < this.limit_porfolio) {
            this.patchState({ loading_designer_porfolio: false, end_of_list_designer_porfolio: true });
          }
        }),
        catchError(rs => {
          console.log(rs);
          if (rs.error.code == "rest_post_invalid_page_number") {
            this.patchState({ loading_designer_porfolio: false, end_of_list_designer_porfolio: true });
            return EMPTY;
          }
          return EMPTY;
        })
      )
    });

    loadAllDesigner$ = this.effect((params$: Observable<{}>) => {
      return params$.pipe(
        tap((params: any) => {
          if (params['page'] == 1) {
            this.setState((state) => adapter.removeAll(state));
            this.patchState({end_of_list: false});
          }
          this.patchState({ loading: true })
        }),
        concatMap((params: any) => {
          const query_object = [];
          if (params['design_category_id']) {
            query_object.push({
              taxonomy_type: 'design_category',
              taxonomy_value: [params['design_category_id']]
            })
          }
          if (params['certified']) {
            query_object.push({
              taxonomy_type: 'blue_tick',
              taxonomy_value: [params['certified']]
            })
          }
          return this.marketPlaceSv.listAll('designer', params['page'], this.limit, query_object).pipe(
            take(1),
            concatMap(
              (desinger_list: any[]) => {
                if (desinger_list && desinger_list.length > 0 ) {
                  const promises: any = [];
                  desinger_list.forEach(designer => {
                    promises.push(this.marketPlaceSv.listAll('project', 1, 6, params['design_category_id'] ?
                      [
                        {
                          taxonomy_type: 'design_category',
                          taxonomy_value: [params['design_category_id']]
                        },
                        {
                          taxonomy_type: 'designer_id',
                          taxonomy_value: [designer.id]
                        }
                      ] :
                      [
                        {
                          taxonomy_type: 'designer_id',
                          taxonomy_value: [designer.id]
                        }
                      ]
                    ).pipe(take(1)).toPromise())
                  });
                  return combineLatest(Promise.all(promises), of(desinger_list)).pipe(
                    map(([portfolio_list, designer_list]) => {
                      if (designer_list && designer_list.length > 0) {
                        designer_list.forEach((designer, index) => designer_list[index]={
                          ...designer,
                          portfolios: portfolio_list[index]
                        });
                        return designer_list;
                      } else {
                        return [];
                      }
                    })
                  );
                }
                return of([])
              }
            ),
            catchError(rs => {
              if (rs.error.code == "rest_post_invalid_page_number")
                this.patchState({ loading: false, end_of_list: true });
              else {
                console.log(rs);
              }
              return of([]);
            })
          )
        }),
        map(
          (designer_list_final: any[]) => {
            if (designer_list_final && designer_list_final.length > 0) {
              designer_list_final.forEach(designer => {
                this.setState((state) => adapter.setOne(designer, state));
              });
              this.patchState({ loading: false })
            }
            if (designer_list_final.length == 0 || designer_list_final.length < this.limit) {
              this.patchState({ loading: false, end_of_list: true });
            }
          }
        )
      )
    });
}
