<div [ngSwitch]="approved">
    <div *ngSwitchCase="1">
        <nz-tag nzColor="success">Approved</nz-tag>
    </div>
    <div *ngSwitchCase="2">
        <nz-tag nzColor="warning">Unapproved</nz-tag>
    </div>
    <div *ngSwitchDefault>
        <nz-tag>N/A</nz-tag>
    </div>
</div>