import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { CommonUtils } from '@penji/shared/utils';
import { getAllCategory } from '@penji/team/categories/data-access';
import { DesignerTeamStoreService } from '@penji/team/designer-team/data-access';
import { SquadGroupStoreService } from '@penji/team/squad-group/data-access';
import { TemplateAnswerStoreService } from '@penji/team/template-answer/data-access';
import { TicketCateStoreService } from '@penji/team/ticket/data-access';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'penji-element-input',
  standalone: true,
  imports: [CommonModule, NzSelectModule, FormsModule, NzDatePickerModule, NzInputModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit {
  @Input() input_type!: string;
  @Input() input_selected: any;
  @Input() show_title = true;
  @Input() show_padding = true;
  @Output() output_value = new EventEmitter();
  list$: any;
  store = inject(Store);
  ticketCateStore = inject(TicketCateStoreService);
  SquadGroupStoreService = inject(SquadGroupStoreService);
  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  TemplateAnswerStoreService = inject(TemplateAnswerStoreService);
  country = CommonUtils.country;
  list_industries = CommonUtils.industries;
  inputValue(term: string | number) {
    if (term) {
      this.output_value.emit(Number(term) ? term : term.toString().trim());
    } else {
      this.output_value.emit('');
    }
  }
  inputDateValue(result: Date[]) {
    this.output_value.emit(result);
  }
  ngOnInit(): void {
    switch (this.input_type) {
      case 'designer_team':
        this.list$ = this.DesignerTeamStoreService.data$;
        break;
      case 'squad_group':
        this.list$ = this.SquadGroupStoreService.data$;
        break;
      case 'category':
        this.list$ = this.store.pipe(select(getAllCategory));
        break;
      case 'ticket_main_category_id':
        this.list$ = this.ticketCateStore.getListMainTicketCate();
        break;
      case 'ticket_sub_category_id':
        this.list$ = this.ticketCateStore.getListSubTicketCate();
        break;
      case 'question_category':
        this.TemplateAnswerStoreService.loadQuestionCategory$();
        this.list$ = this.TemplateAnswerStoreService.list_question_category$;
        break;
      default:
        break;
    }
  }
}
