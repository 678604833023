import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ProjectAssignedComponent } from '@penji/team/project/project-ui/project-assigned';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Project, ProjectService, WhereQueryInterface } from '@penji/shared/data-access';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { ProjectStatusComponent } from '@penji/team/project/project-ui/project-status';
import { RouterModule } from '@angular/router';
import { InputComponent } from '@penji/shared/ui/element/input';
import { CategoryTitleComponent } from '@penji/team/categories/category-ui/category-title';

@Component({
  selector: 'penji-project-by-team-widget',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    ProjectStatusComponent,
    ProjectAssignedComponent,
    CategoryTitleComponent
  ],
  templateUrl: './w-project-by-team.component.html',
  styleUrls: ['./w-project-by-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WProjectByTeamComponent {

  @Input() limit = -1;

  list_project$!: Observable<Project[]>;
  designer_team_id$ = new BehaviorSubject<string>('');

  constructor(private projectSV: ProjectService) {
    this.list_project$ = this.designer_team_id$.pipe(
      switchMap(designer_team_id => {
        const where_query: WhereQueryInterface[] = [
          {
            field_name: 'status',
            field_operator: 'in',
            field_value: [1,2]
          },
          {
            field_name: 'in_queue',
            field_operator: '==',
            field_value: false
          },
          {
            field_name: 'pending_review',
            field_operator: '==',
            field_value: false
          },
          {
            field_name: 'pause',
            field_operator: '==',
            field_value: false
          }
        ];

        if (designer_team_id) {
          where_query.push({
            field_name: 'assign_team_designer', field_operator: '==', field_value: designer_team_id
          })
        }
        return this.projectSV.listProjectRealTime(this.limit, where_query);
      })
    )
  }

  inputDesignerTeam(value: string) {
    this.designer_team_id$.next(value);
  }

}
