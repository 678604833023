export class Permission {
    id?: string;
    customer?: boolean;
    member?: boolean;
    project?: boolean;
    designer_team?: boolean;
    squad_group?: boolean;
    category?: boolean;
    ticket?: boolean;
    checkin?: boolean;
    request_off?: boolean;
    logs?: boolean;
    log_type = 'permission'
}
export const initLeaderPermission = {
    customer: true,
    member: true,
    project: true,
    designer_team: true,
    squad_group: true,
    category: true,
    ticket: true,
    checkin: true,
    request_off: true,
    logs: true
} 
export const initMemberPermission = {
    customer: true,
    member: false,
    project: true,
    designer_team: false,
    squad_group: false,
    category: false,
    ticket: true,
    checkin: true,
    request_off: true,
    logs: false
}
export const initPermission = {
    customer: false,
    member: false,
    project: false,
    designer_team: false,
    squad_group: false,
    category: false,
    ticket: false,
    checkin: false,
    request_off: false,
    logs: false
}