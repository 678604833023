import { CommonModule, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { InputSelectFormComponent } from './input-select-form/input-select-form.component';

@Component({
  selector: 'penji-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    KeyValuePipe,
    InputSelectFormComponent
  ],
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsFormComponent implements OnInit {
  @Input() formGroup!: UntypedFormGroup;
  @Input() settings?: object;
  settings_form = new UntypedFormGroup({});
  ngOnInit(): void {
    if (this.settings) {
      for (const key in this.settings) {
        this.settings_form.addControl(key, new UntypedFormControl());
      }
      this.formGroup.addControl('settings', this.settings_form);
    }
  }
}
