import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FaqStoreService } from '@penji/marketplace/shared/data-access';

@Component({
  selector: 'penji-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  faqStore = inject(FaqStoreService);

  constructor() {
    this.faqStore.loadAllFaq$();
  }
}
