/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { Permission, initLeaderPermission, initMemberPermission } from '../models/permission.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map } from 'rxjs';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService extends LogService<Permission> {

    nzMessageService = inject(NzMessageService);
    createPermission(user_id: string, type: 'leader' | 'member' = 'member') {
        let permission: Permission;
        if (type === 'leader')
            permission = initLeaderPermission as Permission;
        else
            permission = initMemberPermission as Permission;
        return this.afs.firestore.collection('permission').doc(user_id).set({
            ...permission
        }, { merge: true }).then(() => {
            this.log_model.action = 'create';
            this.log_model.data = { ...new Permission, ...permission };
            this.createLog();
            this.nzMessageService.success('create successfully permission!');
            return { flag: true, message: 'create successfully permission!' };
        })
    }
    updatePermission(user_id: string, key:string, value: boolean) {
        return this.afs.firestore.collection('permission').doc(user_id).set({ [key]: value}, { merge: true }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new Permission, ...{ [key]: value}};
            this.createLog();
            this.nzMessageService.success('update successfully permission!');
            return { flag: true, message: 'update successfully permission!' };
        })
    }
    getPermissionUser(user_id: string) {
        return this.afs.collection('permission').doc<Permission>(user_id).valueChanges().pipe(map(rs => ({ id: user_id, ...rs } as Permission)));
    }
}
