import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Overtime, OvertimeService, WhereQueryInterface } from '@penji/shared/data-access';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthStore } from '@penji/shared/auth/data-access';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import * as firebase from 'firebase/firestore';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { OvertimeApprovedComponent } from '@penji/team/overtime/overtime-ui/overtime-approved';
import { InputComponent } from '@penji/team/shared/shared-ui/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'penji-w-overtime',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    UserItemComponent,
    OvertimeApprovedComponent
  ],
  templateUrl: './w-overtime.component.html',
  styleUrls: ['./w-overtime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WOvertimeComponent {
  @Input() limit = 20;

  overtimeSV = inject(OvertimeService);
  modal = inject(NzModalService);
  authStore = inject(AuthStore);

  authProfile$ = this.authStore.authProfile$;
  list_overtime$!: Observable<Overtime[]>;

  list$ = new BehaviorSubject<{}>({});
  action: any = {};
  constructor() {
    this.list_overtime$ = this.list$.pipe(switchMap((action: any) => {
      this.action = { ...action };
      const where_query: Array<WhereQueryInterface> = [];
      let start_after;
      let end_before;
      if (action) {
        for (const k in action) {
          if (k == 'limit') {
            this.limit = action[k];
          } else if (k == 'start_after') {
            start_after = action[k];
          } else if (k == 'end_before') {
            end_before = action[k];
          } else if (k == 'start_at') {
            where_query.push({
              field_name: 'created_at',
              field_operator: '>=',
              field_value: firebase.Timestamp.fromDate(new Date(new Date(action[k]).setHours(0, 0, 0)))
            });
          } else if (k == 'end_at') {
            where_query.push({
              field_name: 'created_at',
              field_operator: '<=',
              field_value: firebase.Timestamp.fromDate(new Date(new Date(action[k]).setHours(23, 59, 59)))
            });
          } else if (k == 'approved') {
            where_query.push({
              field_name: k,
              field_operator: '==',
              field_value: parseInt(action[k])
            });
          } else {
            where_query.push({
              field_name: k, field_operator: '==',
              field_value: action[k] == 'true' ? true : action[k] == 'false' ? false : action[k]
            });
          }
        }
      }
      return this.overtimeSV.listOvertimeRealTime(this.limit, where_query, start_after, end_before);
    }));
  }
}
