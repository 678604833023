import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzImageModule } from 'ng-zorro-antd/image';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'penji-footer',
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzButtonModule,
    NzImageModule,
    SlickCarouselModule,
    NzGridModule,
    NzCollapseModule,
    NzDividerModule,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @ViewChild('slickModal') slickModal: any;

  responsiveSlider = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        prevArrow: false,
        nextArrow: false,
      },
    },
  ];

  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    pauseOnHover: true,
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: this.responsiveSlider,
    prevArrow: `<button type="button" class="slick-prev !h-11 !w-11 before:!content-[none] !bg-white !text-gray-400 !rounded-full !text-lg !z-10 !-left-[15px] !flex !items-center !justify-center !shadow hover:shadow-lg hover:!bg-gray-50">
    <svg height="22" width="22" fill="currentColor" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>
    </button>`,
    nextArrow: `<button type="button" class="slick-next !h-11 !w-11 before:!content-[none] !bg-white !text-gray-400 !rounded-full !text-lg !z-10 !-right-[15px] !flex !items-center !justify-center !shadow hover:shadow-lg hover:!bg-gray-50">
    <svg height="22" width="22" fill="currentColor" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>
    </button>`,
  };

  footerData = {
    companies: [
      {
        name: 'Entrepreneur',
        logo: './assets/images/layout-images/footer-logos/Entrepreneur 2.svg',
      },
      {
        name: 'Huffpost',
        logo: './assets/images/layout-images/footer-logos/Huffpost 2.svg',
      },
      {
        name: 'Inc',
        logo: './assets/images/layout-images/footer-logos/Inc 2.svg',
      },
      {
        name: 'Philadelphia',
        logo: './assets/images/layout-images/footer-logos/Philadelphia 2.svg',
      },
      {
        name: 'Technically',
        logo: './assets/images/layout-images/footer-logos/Technically 2.svg',
      },
      {
        name: 'WashingtonPost',
        logo: './assets/images/layout-images/footer-logos/WashingtonPost.svg',
      },
      {
        name: 'Technically',
        logo: './assets/images/layout-images/footer-logos/Technically 2.svg',
      },
      {
        name: 'Inc',
        logo: './assets/images/layout-images/footer-logos/Inc 2.svg',
      },
    ],

    socials: [
      {
        url: 'https://www.facebook.com/dotpenji',
        platform: 'facebook',
      },
      {
        url: 'https://www.instagram.com/penji.co',
        platform: 'instagram',
      },
      {
        url: 'https://www.linkedin.com/company/dotpenji',
        platform: 'linkedin',
      },
    ],

    bgUrl: './assets/images/layout-images/footer-illus/Footer Illustration.svg',

    items: [
      {
        title: 'Service',
        url: '#',
        active: false,
        items: [
          { title: 'Why Penji?', url: 'https://penji.co/why-penji' },
          { title: 'How it works', url: 'https://penji.co/how-it-works' },
          { title: 'Our Work', url: 'https://penji.co/our-work' },
          { title: 'Pricing', url: 'https://penji.co/pricing' },
          { title: 'Reviews', url: 'https://penji.co/reviews' },
          { title: 'Watch demo', url: 'https://penji.co/get-a-demo' },
        ],
      },
      {
        title: 'Resources',
        url: '#',
        active: false,
        items: [
          { title: 'Partners', url: 'https://penji.co/partners' },
          { title: 'Learning center', url: 'https://penji.co/learning-center' },
          {
            title: 'Customer stories',
            url: 'https://penji.co/customer-stories',
          },
          {
            title: 'Scope of Service',
            url: 'https://penji.co/scope-of-service',
          },
        ],
      },
      {
        title: 'Company',
        url: '#',
        active: false,
        items: [
          { title: 'Our Mission', url: 'https://penji.co/company' },
          { title: 'Careers', url: 'https://penji.co/careers' },
          { title: 'Media kit', url: 'https://penji.co/media-kit' },
          { title: 'Penji for Good', url: 'https://penji.co/programs' },
          { title: 'Press', url: 'https://penji.co/press' },
        ],
      },
      {
        title: 'Help',
        url: '#',
        active: false,
        items: [
          { title: 'Contact us', url: 'javascript:void(0);' },
          { title: 'Help center', url: 'https://intercom.help/penji/en' },
          {
            title: 'Terms of service',
            url: 'https://penji.co/terms-of-service',
          },
          { title: 'Privacy policy', url: 'https://penji.co/privacy-policy' },
        ],
      },
    ],
  };
}
