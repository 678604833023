import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';

export class Affiliate {
  id!: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  created_at!: Timestamp;
  disable_aff!: boolean;
  discount!: number;
  last_referral?: Timestamp;
  payment_method?: Array<{}>;
  promo_code!: string;
  promo_percent!: number;
  total_amount!: number;
  total_referral!: number;
  type!: string;
  log_type = 'affiliate'
}
