import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { MarketplaceWordpressService } from '@penji/shared/data-access';
import { EMPTY, map, switchMap, tap } from 'rxjs';

export interface DesignCategoryState extends EntityState<any> {
  loading: boolean
}
export const adapter = createEntityAdapter<any>();
export const initialState: DesignCategoryState = adapter.getInitialState({loading: false})
export const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class DesignCategoryStoreService extends ComponentStore<DesignCategoryState> {
  constructor(private marketPlaceSv: MarketplaceWordpressService) {
    super(initialState);
  }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);

  getOneDesignCategoryById(design_category_id: any) {
    return this.select((s) => s.entities[design_category_id]);
  }
  getOneDesignCategoryBySlug(design_category_slug: string) {
    return this.select(
      this.data$,
      (rs) => rs.filter((cate) => cate.slug == design_category_slug)[0]
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  loadAllDesignCategoryCate$ = this.effect((params$) => {
    return params$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(() => {
        return this.marketPlaceSv.listAll('design_category');
      }),
      map(list => {
        if (list && list.length > 0) {
          const temp = [...list];
          this.setState((state) => adapter.setAll(temp, state));
          this.patchState({ loading: false });
          return EMPTY;
        } else {
          this.patchState({ loading: false });
          return EMPTY;
        }
      })
    );
  });
}
