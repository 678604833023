import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { LogService } from './log.service';
import { Ticket, TicketCategory, TicketSubCategory } from '../models/ticket.model';
import { WhereQueryInterface } from '../interfaces/where-query-interface';

@Injectable({
    providedIn: 'root'
})
export class TicketService extends LogService<Ticket>{

    async listTicket(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string) {
        try {
            let query: Query = this.afs.firestore.collection('ticket_v2');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', 'desc');

            if (start_after) {
                const doc = await this.afs.firestore.doc(`ticket_v2/${start_after}`).get();
                query = query.startAfter(doc).limit(limit);
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`ticket_v2/${end_before}`).get();
                query = query.endBefore(doc).limitToLast(limit);
            } else {
                query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list: Array<Ticket> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as Ticket;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    getTicketDetail(ticket_id: string) {
        return this.afs.doc<Ticket>(`ticket_v2/${ticket_id}`).valueChanges({ idField: 'id' });
    }

    addTicket(ticket: Ticket) {
        return this.afs.collection(`ticket_v2`).add({ ...ticket }).then(rs => {
            this.log_model.action = 'create';
            this.log_model.data = { ... new Ticket, ...ticket };
            this.createLog();
            return ({ flag: true, message: 'Ticket successfully added!', data: { ...ticket, id: rs.id } });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message, data: {} as Ticket });
        });
    }

    updateTicket(ticket: Ticket | any) {
        if (ticket.doc) delete ticket.doc;
        return this.afs.doc(`ticket_v2/${ticket.id}`).update({ ...ticket }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ... new Ticket, ...ticket };
            this.createLog();
            return ({ flag: true, message: 'Ticket successfully updated!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    deleteTicket(ticket_id: string) {
        return this.afs.doc(`ticket_v2/${ticket_id}`).delete().then(() => {
            this.log_model.action = 'delete';
            this.log_model.data = { ... new Ticket, ticket_id: ticket_id } as Ticket;
            this.createLog();
            return ({ flag: true, message: 'Ticket successfully deleted!' });
        }).catch((err: any) => {
            return ({ flag: false, message: err.message });
        });
    }

    // listActivitiesTicket(
    //     ticket_id: string,
    //     limit: number = 20,
    //     where_query?: [{ field_name: string, field_operator: WhereFilterOp, field_value: string | number }],
    //     start_after?: QueryDocumentSnapshot<DocumentReference>,
    //     end_before?: QueryDocumentSnapshot<DocumentReference>) {
    //     try {
    //         let query: Query = this.afs.firestore.collection(`ticket_v2/${ticket_id}/activities`);

    //         if (where_query && where_query.length > 0) {
    //             where_query.forEach(q => {
    //                 query = query.where(q.field_name, q.field_operator, q.field_value);
    //             })
    //         }

    //         query = query.orderBy('created_at', 'desc');

    //         if (start_after) {
    //             query = query.startAfter(start_after).limit(limit);
    //         } else if (end_before) {
    //             query = query.endBefore(end_before).limitToLast(limit);
    //         } else {
    //             query = query.limit(limit);
    //         }

    //         return query.get().then(querySnapshot => {
    //             const list = Array<TicketActivities>();
    //             querySnapshot.forEach((doc: any) => {
    //                 const data = doc.data() as TicketActivities;
    //                 data.ticket_id = doc.id;
    //                 data.activity_doc = doc;
    //                 data.user_id = doc.data().user_id;
    //                 data.content = doc.data().content;
    //                 list.push(data);
    //             });
    //             return list;
    //         });
    //     } catch (error) {
    //         console.log(error);
    //         return null;
    //     }
    // }

    // listDiscussionTicket(
    //     ticket_id: string,
    //     limit: number = 20,
    //     where_query?: [{ field_name: string, field_operator: WhereFilterOp, field_value: string | number }],
    //     start_after?: QueryDocumentSnapshot<DocumentReference>,
    //     end_before?: QueryDocumentSnapshot<DocumentReference>) {
    //     try {
    //         let query: Query = this.afs.firestore.collection(`ticket_v2/${ticket_id}/discussion`);

    //         if (where_query && where_query.length > 0) {
    //             where_query.forEach(q => {
    //                 query = query.where(q.field_name, q.field_operator, q.field_value);
    //             })
    //         }

    //         query = query.orderBy('created_at', 'desc');

    //         if (start_after) {
    //             query = query.startAfter(start_after).limit(limit);
    //         } else if (end_before) {
    //             query = query.endBefore(end_before).limitToLast(limit);
    //         } else {
    //             query = query.limit(limit);
    //         }

    //         return query.get().then(querySnapshot => {
    //             const list = Array<TicketDiscussion>();
    //             querySnapshot.forEach((doc: any) => {
    //                 const data = doc.data() as TicketDiscussion;
    //                 data.discussion_id = doc.id;
    //                 data.discussion_doc = doc;
    //                 data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
    //                 list.push(data);
    //             });
    //             return list;
    //         });
    //     } catch (error) {
    //         console.log(error);
    //         return null;
    //     }
    // }

    listTicketCategoryV2() {
        try {
            return this.afs.firestore.collection('ticket_category_v2')
                .get().then(querySnapshot => {
                    const list = Array<TicketCategory>();
                    querySnapshot.forEach((doc: any) => {
                        const data = doc.data() as TicketCategory;
                        data.id = doc.id;
                        list.push(data);
                    });
                    return list;
                });
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    listTicketSubCategoryV2(ticket_category_id: string) {
        try {
            return this.afs.firestore.collection(`ticket_category_v2/${ticket_category_id}/ticket_sub_category`)
                .get().then(querySnapshot => {
                    const list = Array<TicketSubCategory>();
                    querySnapshot.forEach((doc: any) => {
                        const data = doc.data() as TicketSubCategory;
                        data.id = doc.id;
                        list.push(data);
                    });
                    return list;
                });
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}
