import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { MarketplaceSharedSharedUiMenuComponent } from '@penji/marketplace/shared/shared-ui/menu';
import { MarketplaceSharedSharedUiMenuButtonsComponent } from '@penji/marketplace/shared/shared-ui/menu-buttons';

@Component({
  selector: 'penji-header',
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    NzButtonModule,
    NzImageModule,
    NzIconModule,
    NzDrawerModule,
    MarketplaceSharedSharedUiMenuComponent,
    MarketplaceSharedSharedUiMenuButtonsComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  // @HostListener("window:scroll", ['$event'])
  // onWindowScroll(e: any) {
  //   const header = document.getElementById('layout-header');
  //   if(window.pageYOffset >= 72){
  //     header?.classList.add('active')
  //   }
  //   if(window.pageYOffset < 72){
  //     header?.classList.remove('active')
  //   }
  // }
  visibleMenu = false;

  openMenu(): void {
    this.visibleMenu = true;
  }

  closeMenu(): void {
    this.visibleMenu = false;
  }
}
